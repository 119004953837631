import uuid from 'uuid/v4';
import axios from "axios";
import DeviceAdded from '../events/DeviceAdded'
import SyncEventsPushed from '../events/SyncEventsPushed'

const batchSize = 25;
const streamsToIgnore = ['InstructorLogins', 'Sync-'];

export default class SyncController {
  /**
   * @param {appRouterBuilder} buildAppRouter
   * @param {DBPool} dbPool
   * @param {EventStore} eventStore
   * @param {Mapper} mapper
   * @param {ReadRepository} readRepository
   * @param {Logger} logger
   */
  constructor ({ buildAppRouter, dbPool, eventStore, mapper, readRepository, logger }) {
    this._router = buildAppRouter('sync', 1);
    this._readRepository = readRepository;
    this._dbPool = dbPool;
    this._eventStore = eventStore;
    this._mapper = mapper;
    this._logger = logger;

    this._router.command('syncFromServer', this.syncFromServer.bind(this));
    this._router.command('syncToServer', this.syncToServer.bind(this));
    this._router.findOne('info', this.info.bind(this));
    this._router.command('resetDevice', this.resetDevice.bind(this));
  }

  async syncFromServer ({ user, body, headers }) {
    if (!headers['Authorization']) return this._router.forbidden({message: "You need an authentication from the server"});

    let {deviceId, lastFetchedPosition: lastPosition} = (await this._readRepository.findOne('sync', {}, true)) || {};
    if (deviceId === undefined) {
      deviceId = uuid();
      lastPosition = 0;
      await this._eventStore.save('Sync-' + deviceId, [new DeviceAdded(deviceId)], -1, {source: deviceId, timestamp: Date.now()});
    }

    let done = false;
    while(!done) {
      const {data} = await axios.get(`${process.env.PUBLIC_URL}/api/v1/sync/fetch?deviceId=${deviceId}&lastPosition=${lastPosition}`, {headers});
      const {events, hasMore} = data;
      done = !hasMore;
      for (const eventData of events) {
        try {
          // eventId:string, eventType:string, streamId:string, data:object, metadata:object
          eventData.metadata = {
            source: 'server',
            serverPosition: eventData.position,
            timestamp: eventData.createdEpoch,
            ...eventData.metadata
          };
          await this._eventStore.appendToStream(eventData.streamId, eventData, eventData.eventNumber - 1);
          lastPosition = eventData.position;
        } catch (err) {
          this._logger.debug(`Could not save ${eventData.eventNumber}@${eventData.streamId}`, err.stack);
          return this._router.error({message: 'Sync could not complete. Please try again later.'});
        }
      }
    }

    return {done};
  }

  async syncToServer ({user, body, headers}) {
    if (!headers['Authorization']) return this._router.forbidden({message: "You need an authentication from the server"});

    let {deviceId, lastPushedPosition} = (await this._readRepository.findOne('sync', {}, true)) || {};
    if (deviceId === undefined) {
      return this._router.badRequest({message: 'No deviceId, please sync from server first to generate one.'});
    }

    let isEndOfStream = false;
    let fromPosition = this._eventStore.createPosition(lastPushedPosition);
    while(!isEndOfStream) {
      const res = await this._eventStore.readAllBatch(fromPosition, batchSize);
      isEndOfStream = res.isEndOfStream;
      fromPosition = res.nextPosition;

      const data = {
        deviceId,
        events: res.events.filter(ev => {
          if (ev.metadata && ev.metadata.source && ev.metadata.source !== deviceId) return false;
          if (streamsToIgnore.find(x => ev.streamId.startsWith(x))) return false;
          return ev.position.value > lastPushedPosition;
        }).map(ev => {
          return {
            ...ev,
            position: ev.position.value,
          }
        })
      };
      if (data.events.length === 0) continue;
      const {data: {success, error, lastPosition}} = await axios.post(`${process.env.PUBLIC_URL}/api/v1/sync/push`, data, {headers});
      try {
        await this._eventStore.save('Sync-' + deviceId, [new SyncEventsPushed(deviceId, lastPosition)], -2, {source: deviceId, timestamp: Date.now()});
      } catch (err) {
        this._logger.warn('Saving SyncEventsPushed event failed:', err);
      }
      if (!success) {
        this._logger.debug(`Sync to server failed: lastPosition=${lastPosition}`, error);
        return this._router.error({message: 'Sync could not complete. Please try again later.'});
      }
    }

    return {};
  }

  async info({headers}) {
    if (!headers['Authorization']) return this._router.forbidden({message: "You need an authentication from the server"});

    return (await this._readRepository.findOne('sync', {}, true)) || {
      deviceId: '',
      lastFetchedEventTimestamp: 0,
      lastPushTimestamp: 0
    };
  }

  async resetDevice({user}) {
    if (!user) return this._router.unauthorized();
    if (!user.roles.includes('admin')) return this._router.forbidden();

    await this._mapper.resetData(this._dbPool);
    await this._eventStore.resetData();

    return {};
  }
}
