import InstructorLoggedIn from '../events/InstructorLoggedIn'

export default async function bootstrap(services) {
  const {readRepository, eventStore, logger} = services;
  // registering auth services
  const findUser = ({ scope, ...query }) => readRepository.findOne('users', query, true);
  const onAuthSuccess = async(user) => {
    try {
      const metadata = {
        timestamp: Date.now(),
        source: 'api'
      };
      // We keep the login events in InstructorLogins stream because they're not related to the Instructor aggregate
      await eventStore.save(`InstructorLogins-${user.userId}`, [new InstructorLoggedIn(user.userId)], metadata);
    } catch (err) {
      logger.warn(`Could not save InstructorLoggedIn to InstructorLogins-${user.userId}:`, err.stack);
    }
  };
  Object.assign(services, { findUser, onAuthSuccess });

  // register extra services here
}
