import _uuid from 'uuid'
import Mapper from './Mapper'
import CheckPointStore from './CheckPointStore'
import { createDefaultStores } from './utils'
import { DBPool } from './store'

export const uuid = _uuid

/**
 * @param {Logger} services.logger
 * @param {ModelDefinition[]} services.modelDefs
 * @returns {{dbPool: DBPool, checkPointStoreFactory: (function(string): CheckPointStore), mapper: Mapper}}
 */
export default async function factory (services) {
  const { logger = console, modelDefs = [] } = services

  await createDefaultStores('readModels')
  const dbPool = new DBPool('readModels')

  const mapper = new Mapper(modelDefs, logger)
  const checkPointStoreFactory = (key) => new CheckPointStore(dbPool, key)

  Object.assign(services, {
    mapper,
    dbPool,
    checkPointStoreFactory
  })
  return services
}


