import React, {Component} from "react"
import PropTypes from 'prop-types'
import {connect} from "react-redux"
import { bindActionCreators } from 'redux'
import {addInstructor} from '../actions/instructors'
import {Form} from "react-bootstrap";

const defaultState = {
  added: false,
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  isAdmin: false
};

export class AddInstructor extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    // state
    adding: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    // actions
    addInstructor: PropTypes.func.isRequired,
  };

  state = Object.assign({}, defaultState);

  setFormValue = (evt) => this.setState({[evt.target.name]: evt.target.value});

  setFormCheckbox = (evt) => this.setState({[evt.target.name]: evt.target.checked});

  onSubmit = async(evt) => {
    evt.preventDefault();

    const {addInstructor, history} = this.props;
    const {added, isAdmin, ...data} = this.state;
    try {
      await addInstructor({...data, roles: isAdmin ? ['admin'] : []});
      history.goBack();
    } catch (err) {
      //nothing
    }

    return false;
  };

  render() {
    return <div id="AddInstructorPage">
      <h3>Add instructor</h3>
      {this.state.added && <div className="success">Instructor added</div>}
      <div className="error">{this.props.error}</div>
      <div>
        <Form onSubmit={this.onSubmit}>
          <Form.Group>
            <Form.Label>First name:</Form.Label>
            <Form.Control type="text" name="firstName" value={this.state.firstName} onChange={this.setFormValue} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last name:</Form.Label>
            <Form.Control type="text" name="lastName" value={this.state.lastName} onChange={this.setFormValue} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Username:</Form.Label>
            <Form.Control type="text" name="username" value={this.state.username} onChange={this.setFormValue} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password:</Form.Label>
            <Form.Control type="password" name="password" value={this.state.password} onChange={this.setFormValue} />
          </Form.Group>
          <Form.Group>
            <Form.Check type="checkbox" label="Admin" name="isAdmin" checked={this.state.isAdmin} onChange={this.setFormCheckbox} />
          </Form.Group>
          <Form.Group>
            <input disabled={this.props.adding} type="submit" value="Add" />
          </Form.Group>
        </Form>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  ...state.addInstructor
});
const mapDispatchToProps = dispatch => bindActionCreators({
  addInstructor
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddInstructor);
