import {ADD_GLOBAL_SKILL, ADD_GLOBAL_SKILL_FAILED, GLOBAL_SKILL_ADDED} from "../actions/addGlobalSkill";
import {errorMessageFromPayload} from "../utils/errors";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  adding: false,
  error: '',
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_GLOBAL_SKILL: {
      return {
        ...state,
        adding: true,
        error: '',
      };
    }
    case GLOBAL_SKILL_ADDED: {
      return {
        ...state,
        adding: false,
      };
    }
    case ADD_GLOBAL_SKILL_FAILED: {
      return {
        ...state,
        adding: false,
        error: errorMessageFromPayload(action.payload),
      };
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
