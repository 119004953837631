import _uuid from "uuid";
import EventStore from "./EventStore";
import StreamReader from '../StreamReader'
import Subscriber from '../Subscriber'

export const uuid = _uuid;

export default async function(services) {
  const { logger, config: { eventStore: esConfig } } = services;
  if (!logger) {
    throw new Error('Missing logger in services registry.');
  }
  if (!esConfig) {
    throw new Error('Missing "eventStore" section in config.');
  }
  const eventStoreFactory = () => new EventStore(logger);
  const eventStore = eventStoreFactory();
  services.eventStoreFactory = eventStoreFactory;
  services.esStreamReaderFactory = (eventStore, streamName, startFrom, batchSize) => new StreamReader(eventStore, streamName, startFrom, null, batchSize);
  services.subscriberFactory = (eventStore, updateLastCheckPoint, name = 'default') => new Subscriber(name, eventStore, updateLastCheckPoint, null, logger);
  services.eventStore = eventStore;
}
