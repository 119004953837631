import React from "react";
import PropTypes from "prop-types";
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";

const Forbidden = () => <div>Access forbidden</div>;

const PrivateRoute = ({ component: Component, exact, path, requiresRole, auth, ...rest }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={props =>
        auth.isLoggedIn() ?
          !requiresRole || auth.hasRole(requiresRole) ? (
            <Component {...props} {...rest} />
          )
            : (
              <Forbidden />
            )
          : (
          <Redirect
            to={{
              pathname: `/login`,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  //scope: PropTypes.string.isRequired,
  location: PropTypes.object,
  requiresRole: PropTypes.string
};
const mapStateToProps = state => ({
  auth: state.auth.auth
});
export default connect(mapStateToProps)(PrivateRoute);
