import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button} from 'react-bootstrap';
import {getSkillOptions} from "../actions/skillOptions";
import {getGlobalSkills} from "../actions/globalSkills";

export class SkillOptionsPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    // state
    list: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    // action
    getSkillOptions: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    await this.props.getGlobalSkills();
    await this.props.getSkillOptions({order: 'createdAt ASC'});
  }

  addClick = () => {
    this.props.history.push('/skillOption/add');
  };

  render() {
    const {list, globalSkills} = this.props;
    return <div>
      <h3>Comments</h3>
      <Button onClick={this.addClick}>Add</Button>
      <table>
        <thead>
        <tr>
          <th>Global Skill</th>
          <th>Name</th>
        </tr>
        </thead>
        <tbody>
        {list.map(({skillOptionId, globalSkillId, name}) => (
          <tr key={skillOptionId}>
            <td>{(globalSkills[globalSkillId] || {name: 'N/A'}).name}</td>
            <td>{name}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>;
  }
}

const mapStateToProps = state => ({
  ...state.skillOptions,
  globalSkills: state.globalSkills.map,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  getSkillOptions,
  getGlobalSkills,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SkillOptionsPage);
