import moment from 'moment';
import uuid from'uuid';
import AssessSkill from "../commands/AssessSkill";
import Assessment from "../domain/Assessment";

export default class AssessmentController {
    /**
     * @param buildAppRouter
     * @param readRepository
     * @param {commandHandler} commandHandler
     */
    constructor({buildAppRouter, readRepository, commandHandler}) {
        this._router = buildAppRouter('assessment', 1);

        this._router.command(AssessSkill, async({user, body}) => {
            if (!user) return this._router.unauthorized();
            const now = moment().toISOString();
            const cmd = new AssessSkill(uuid.v4(), body.lessonId, body.maneuverId, body.globalSkillId, body.skillOptionId, body.maneuverTypeId, now);
            await commandHandler(Assessment, cmd.assessmentId, cmd);
            return(cmd.assessmentId);
        });

        this._router.findByFilter('find', async({user, query}) => {
            if (!user) return this._router.unauthorized();
            return (await readRepository.findByFilter('assessments', query))
        });
    }
}
