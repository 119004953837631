import {Aggregate} from "../infra/aggregate";
import AddSkillOption from "../commands/AddSkillOption";
import SkillOptionAdded from "../events/SkillOptionAdded";
import {ValidationFailed} from "./errors";

export default class SkillOption extends Aggregate {
  constructor() {
    super();

    this._registerCommandHandler(AddSkillOption, SkillOption.addSkillOption);
    this._registerEventHandler(SkillOptionAdded, SkillOption.onSkillOptionAdded);
  }

  static addSkillOption(state, command) {
    const validationErrors = [];
    if (command.skillOptionId === '') validationErrors.push({field: 'skillOptionId', message: 'is required'});
    if (command.globalSkillId === '') validationErrors.push({field: 'globalSkillId', message: 'is required'});
    if (command.name === '') validationErrors.push({field: 'name', message: 'is required'});
    if (validationErrors.length) throw new ValidationFailed(validationErrors);

    if (state.skillOptionId) throw new ValidationFailed('Skill Option already added');

    return [
      new SkillOptionAdded(command.skillOptionId, command.globalSkillId, command.name)
    ];
  }

  static onSkillOptionAdded(state, event) {
    const {skillOptionId} = event;
    return {...state, skillOptionId};
  }
}