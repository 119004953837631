import {Aggregate} from "../infra/aggregate";
import {ValidationFailed} from "./errors";
import LessonStarted from "../events/LessonStarted";
import StartLesson from "../commands/StartLesson";
import LessonCompleted from "../events/LessonCompleted";
import CompleteLesson from "../commands/CompleteLesson";

export default class Lesson extends Aggregate {
    constructor(){
        super();

        this._registerCommandHandler(StartLesson, Lesson.startLesson);
        this._registerCommandHandler(CompleteLesson, Lesson.completeLesson);

        this._registerEventHandler(LessonStarted, Lesson.onLessonStarted);
        this._registerEventHandler(LessonCompleted, Lesson.onLessonCompleted);
    }

    static async startLesson(state, command) {
        const validationErrors = [];
        if (command.lessonId === '') validationErrors.push({field: 'lessonId', message: 'is required'});
        if (command.instructorId === '') validationErrors.push({field: 'instructorId', message: 'is required'});
        if (command.studentId === '') validationErrors.push({field: 'studentId', message: 'is required'});
        if (command.lessonDateTime === '') validationErrors.push({field: 'lessonDateTime', message: 'is required'});
        if (validationErrors.length) throw new ValidationFailed(validationErrors);

        if (state.lessonId) throw new ValidationFailed('Lesson already started');

        return [
            new LessonStarted(command.lessonId, command.instructorId, command.studentId, command.lessonDateTime),
        ];
    }

    static async completeLesson(state, command) {
        const validationErrors = [];
        if (command.lessonId === '') validationErrors.push({field: 'lessonId', message: 'is required'});
        if (command.timestamp === '') validationErrors.push({field: 'timestamp', message: 'is required'});
        if (command.signature === '') validationErrors.push({field: 'signature', message: 'is required'});
        if (validationErrors.length) throw new ValidationFailed(validationErrors);

        if (state.completed) throw new ValidationFailed('Lesson already complete');

        return [
            new LessonCompleted(command.lessonId, command.timestamp, command.signature),
        ];
    }

    static onLessonStarted(state, event) {
        return {...state, lessonId: event.lessonId, completed: false};
    }

    static onLessonCompleted(state, event) {
        return {...state, lessonId: event.lessonId, completed: true};
    }
}
