import SkillOptionAdded from "../events/SkillOptionAdded";

export const config = {
  key: 'skillOptionId',
  indexes: ['globalSkillId'],
  schema: {
    skillOptionId: {type: 'string', format: 'uuid', nullable: false},
    globalSkillId: {type: 'string', format: 'uuid', nullable: false},
    name: {type: 'string', nullable: false},
    createdAt: {type: 'number', nullable: false},
  }
};

export async function handler(skillOptions, {typeId, event, metadata}) {
  const {timestamp} = metadata;
  switch (typeId) {
    case SkillOptionAdded.type: {
      const {skillOptionId, globalSkillId, name} = event;
      skillOptions.create({skillOptionId, globalSkillId, name, createdAt: timestamp});
      break;
    }
    default:
      break;
  }
  return skillOptions;
}
