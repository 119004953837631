import {localFindOne, remoteCommand, localFind, localCommand} from '../utils/api';

export const GET_STUDENT = 'GET_STUDENT';
export const GET_STUDENT_SUCCESS = 'GET_STUDENT_SUCCESS';
export const GET_STUDENT_FAILED = 'GET_STUDENT_FAILED';
export const getStudent = localFindOne(`v1/student/findOne`, GET_STUDENT, GET_STUDENT_SUCCESS, GET_STUDENT_FAILED);

export const GET_STUDENTS = 'GET_STUDENTS';
export const GET_STUDENTS_SUCCESS = 'GET_STUDENTS_SUCCESS';
export const GET_STUDENTS_FAILED = 'GET_STUDENTS_FAILED';
export const getStudents = localFind(`v1/student/find`, GET_STUDENTS, GET_STUDENTS_SUCCESS, GET_STUDENTS_FAILED);


export const REGISTER_STUDENT = 'REGISTER_STUDENT';
export const REGISTER_STUDENT_SUCCESS = 'REGISTER_STUDENT_SUCCESS';
export const REGISTER_STUDENT_FAILED = 'REGISTER_STUDENT_FAILED';
export const registerStudent = remoteCommand('v1/student/registerStudent', REGISTER_STUDENT, REGISTER_STUDENT_SUCCESS, REGISTER_STUDENT_FAILED);

export const localRegisterStudent = localCommand('v1/student/RegisterStudent', REGISTER_STUDENT, REGISTER_STUDENT_SUCCESS, REGISTER_STUDENT_FAILED);

export const SELECT_STUDENT = 'SELECT_STUDENT';
export function selectStudent(studentId) {
  return {type: SELECT_STUDENT, studentId};
}
