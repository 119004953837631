import EventStore from "./EventStore";

class EventStoreWithConversionWrapper extends EventStore {
  /**
   * @param {EventStore} inner
   * @param {function} converter
   */
  constructor(inner, converter) {
    super();
    this._inner = inner;
    this._converter = converter;
  }

  async read(streamName, start, credentials) {
    const result = await this._inner.read(streamName, start, credentials);
    return result.map(this._converter);
  }

  async readBatch(streamName, start, count, credentials) {
    const {isEndOfStream, nextEventNumber, events} = await this._inner.readBatch(streamName, start, count, credentials);
    return {
      isEndOfStream,
      nextEventNumber,
      events: events.map(this._converter)
    };
  }

  async readAllBatch(position, count, credentials) {
    const {isEndOfStream, nextPosition, events} = await this._inner.readAllBatch(position, count, credentials);
    return {
      isEndOfStream,
      nextPosition,
      events: events.map(this._converter)
    };
  }

  save(streamName, events, expectedVersion, metadata, credentials, options) {
    return this._inner.save(streamName, events, expectedVersion, metadata, credentials, options);
  }

  createPosition(pos) {
    return this._inner.createPosition(pos);
  }

  subscribeToAllFrom(lastCheckPoint, onEventAppeared, liveProcessingStarted, subscriptionDropped, credentials, batchSize) {
    const _onEventAppeared = esData => onEventAppeared(this._converter(esData));
    return this._inner.subscribeToAllFrom(lastCheckPoint, _onEventAppeared, liveProcessingStarted, subscriptionDropped, credentials, batchSize);
  }
}

export default EventStoreWithConversionWrapper;
