import { remoteFind, localFindOne, remoteCommand } from '../utils/api'

export const GET_INSTRUCTOR = 'GET_INSTRUCTOR';
export const GET_INSTRUCTOR_SUCCESS = 'GET_INSTRUCTOR_SUCCESS';
export const GET_INSTRUCTOR_FAILED = 'GET_INSTRUCTOR_FAILED';
export const getInstructor = localFindOne(`v1/instructor/findOne`, GET_INSTRUCTOR, GET_INSTRUCTOR_SUCCESS, GET_INSTRUCTOR_FAILED);


export const ADD_INSTRUCTOR = 'ADD_INSTRUCTOR';
export const ADD_INSTRUCTOR_SUCCESS = 'ADD_INSTRUCTOR_SUCCESS';
export const ADD_INSTRUCTOR_FAILED = 'ADD_INSTRUCTOR_FAILED';
export const addInstructor = remoteCommand('v1/instructor/addInstructor', ADD_INSTRUCTOR, ADD_INSTRUCTOR_SUCCESS, ADD_INSTRUCTOR_FAILED);

export const GET_INSTRUCTORS = 'GET_INSTRUCTORS';
export const GET_INSTRUCTORS_SUCCESS = 'GET_INSTRUCTORS_SUCCESS';
export const GET_INSTRUCTORS_FAILED = 'GET_INSTRUCTORS_FAILED';
export const getInstructors = remoteFind(`v1/instructor/find`, GET_INSTRUCTORS, GET_INSTRUCTORS_SUCCESS, GET_INSTRUCTORS_FAILED);
