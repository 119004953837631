/**
 * @class
 */
import MapperReadResult from './MapperReadResult'
import { Order, Where } from './queries'
import { filter } from '../utils/index'
import { typeOf } from './utils'

const DEFAULT_LIMIT = 100

//TODOs
// - Optimized queries for update, select, delete
// - Proper transaction, right now multi updates and deletes are done in separate trx

export class Mapper {
  /**
   * @param {ModelDefinition[]} modelsDefs
   * @param {Logger} logger
   */
  constructor (modelsDefs, logger) {
    this._logger = logger
    this._modelsDefsArray = modelsDefs
    this._modelsDefs = {}
    for (const modelDef of modelsDefs) {
      this._modelsDefs[modelDef.name] = modelDef
    }
  }

  /**
   * @param {string} modelName
   * @returns {ModelDefinition}
   */
  _getModelDefByName (modelName) {
    const modelDef = this._modelsDefs[modelName]
    if (!modelDef) throw new Error(`Read model "${modelName}" is not registered.`)
    return modelDef
  }

  /**
   * @public
   * @param {ModelDefinition} modelDef
   */
  addModel (modelDef) {
    this._modelsDefs[modelDef.name] = modelDef
  }

  /**
   * Upsert payload into read model
   * @param {object} conn
   * @param {string} modelName
   * @param {object} payload
   * @returns {Promise<void>}
   */
  async upsert (conn, modelName, payload) {
    const modelDef = this._getModelDefByName(modelName)
    modelDef.validatePayload(payload, true)

    const table = await conn.table(modelName)
    await table.put(payload)
  }

  /**
   * Update read model with changes when where condition is met
   * @param {object} conn
   * @param {string} modelName
   * @param {object} changes
   * @param {object} where
   * @returns {Promise<number>}
   */
  async update (conn, modelName, changes, where) {
    const modelDef = this._getModelDefByName(modelName)
    modelDef.validatePayload(changes)

    const _where = new Where(where)
    const table = await conn.table(modelDef.name)
    const rootNode = _where.rootNode()

    const allRecords = await table.getAll()
    const items = filter(allRecords, rootNode)

    let count = 0
    for (const item of items) {
      const oldKey = this._getKeyValue(modelDef, item)
      const newItem = Object.assign({}, item, changes)
      const newKey = this._getKeyValue(modelDef, newItem)

      if (!keysAreIdentical(oldKey, newKey)) {
        const existing = await table.getKey(newKey)
        if (existing) {
          throw new Error(`Item with the key ${newKey} already exists in ${modelDef.name}`)
        }
        await table.delete(oldKey)
      }

      await table.put(newItem)
      count++
    }
    return count

    // let query = buildIndexedQuery(table, _where, modelDef)
    // if (query === table && !_where.isEmptyOrNull()) {// not indexed
    //   query = await query.filter(x => filter([x], rootNode).length)
    // }
    // const primaryKeys = await query.primaryKeys()
    // return table.where(':id').anyOf(primaryKeys).modify((value, ref) => {
    //   ref.value = Object.assign({}, value, changes)
    // })
  }

  /**
   * Select from read model
   * @param {object} conn
   * @param {string} modelName
   * @param {object} _filter
   * @returns {Promise<MapperReadResult>}
   */
  async select (conn, modelName, _filter) {
    const modelDef = this._getModelDefByName(modelName)
    const where = Where.fromFilter(_filter)
    const orderBy = Order.fromFilter(_filter)
    const table = await conn.table(modelDef.name)

    const { results, total } = await this._find(table, modelDef, where, orderBy, _filter)

    return new MapperReadResult(results, total)
  }

  /**
   * Remove a record
   * @param {object} conn
   * @param {string} modelName
   * @param {object} where
   * @returns {Promise<number>}
   */
  async remove (conn, modelName, where) {
    const modelDef = this._getModelDefByName(modelName)
    const _where = new Where(where)
    const table = await conn.table(modelDef.name)

    //TODO extremely slow version for now - use pk, indexes, key-range, cursor to improve speed
    const allRecords = await table.getAll()
    const filteredRecords = filter(allRecords, _where.rootNode())

    let count = 0
    for (const record of filteredRecords) {
      const key = this._getKeyValue(modelDef, record)
      await table.delete(key)
      count++
    }

    return count
  }

  /**
   * Drop read model if it exists
   * @param {object} conn
   * @param {string} modelName
   * @param {number} [version]
   * @returns {Promise<void>}
   */
  async tryDropModel (conn, modelName, version) {
    const modelDef = this._getModelDefByName(modelName)
    const hasTable = await conn.exists(modelName)
    if (hasTable) await conn.deleteTable(modelName)
  }

  /**
   * Create read model if it doesn't exists
   * @param {object} conn
   * @param {string} modelName
   * @param {number} [version]
   * @returns {Promise<void>}
   */
  async tryCreateModel (conn, modelName, version) {
    const modelDef = this._getModelDefByName(modelName)
    await conn.createTable(modelName, modelDef.primaryKey, modelDef.indexes)
  }

  /**
   * Get read model version
   * @param {object} conn
   * @param {string} modelName
   * @returns {Promise<number>}
   */
  async getModelVersion (conn, modelName) {
    const table = await conn.table('$versions')
    const record = await table.get(modelName)
    return (record && record.version) || 1
  }

  /**
   * Set read model version
   * @param {object} conn
   * @param {string} modelName
   * @param {number} version
   * @returns {Promise<void>}
   */
  async setModelVersion (conn, modelName, version) {
    const table = await conn.table('$versions')
    await table.put({ name: modelName, version })
  }

  /**
   * Get read model hash
   * @param {object} conn
   * @param {string} modelName
   * @returns {Promise<string>}
   */
  async getModelHash (conn, modelName) {
    const table = await conn.table('$hashes')
    const record = await table.get(modelName)
    return (record && record.hash) || ''
  }

  /**
   * Set read model hash
   * @param {object} conn
   * @param {string} modelName
   * @param {string} hash
   * @returns {Promise<void>}
   */
  async setModelHash (conn, modelName, hash) {
    const table = await conn.table('$hashes')
    await table.put({ name: modelName, hash })
  }

  /**
   * Reset database data
   * @param {DBPool} conn
   * @returns {Promise<void>}
   */
  resetData(conn) {
    return conn.resetData();
  }

  // filter, order, page

  /**
   * @param {DBTable} table
   * @param {ModelDefinition} modelDef
   * @param {Where} where
   * @param {Order} order
   * @param {number} [skip]
   * @param {number} [limit]
   * @private
   */
  async _find (table, modelDef, where, order, { skip, limit }) {
    const orders = order.getOrders()

    if (!this._indexHasBoolean(modelDef, modelDef.primaryKey)) {
      const byPk = where.getIndexConstraint(modelDef.primaryKey)
      if (byPk.length) {
        const filteredResults = await this._findByIndex(table, modelDef, modelDef.primaryKey, byPk, where)
        return this._sortAndPage(filteredResults, orders, { skip, limit })
      }
    }

    for (const index of modelDef.indexes) {
      if (this._indexHasBoolean(modelDef, index)) continue
      const byIndex = where.getIndexConstraint(index)
      if (byIndex.length) {
        const idxName = index.join('+')
        const idx = table.index(idxName)
        const filteredResults = await this._findByIndex(idx, modelDef, index, byIndex, where)
        return this._sortAndPage(filteredResults, orders, { skip, limit })
      }
    }

    return this._findGeneric(table, modelDef, where, order, { skip, limit })
  }

  /**
   * @param {DBTable|DBIndex} source
   * @param {ModelDefinition} modelDef
   * @param {string[]} index
   * @param {ConstraintNode[]} keyNodes
   * @param {Where} where
   * @returns object[]
   * @private
   */
  async _findByIndex (source, modelDef, index, keyNodes, where) {
    let slowReason = '', results = []
    const rootNode = where.rootNode()
    const start = Date.now()
    if (keyNodes.length === 1) {
      const { operator: op, value } = keyNodes[0]
      switch (op) {
        case 'eq': {
          results = await source.getAll(value)
          break
        }
        case 'neq': {
          results = (await source.getAll()).filter(x => this._getIndexValue(index, x) !== value)
          break
        }
        case 'lte': {
          results = await source.getAll(IDBKeyRange.upperBound(value))
          break
        }
        case 'lt': {
          results = await source.getAll(IDBKeyRange.upperBound(value, true))
          break
        }
        case 'gte': {
          results = await source.getAll(IDBKeyRange.lowerBound(value))
          break
        }
        case 'gt': {
          results = await source.getAll(IDBKeyRange.lowerBound(value, true))
          break
        }
        case 'between': {
          results = await source.getAll(IDBKeyRange.bound(value[0], value[1]))
          break
        }
        case 'inq': {
          const values = value.sort()
          results = await source.getAll(IDBKeyRange.bound(values[0], values[value.length - 1]))
          slowReason = 'inq uses lower and upper bound'
          break
        }
        case 'ilike':
          results = await source.getAll()
          slowReason = 'impossible to optimize ilike'
          break
        default:
          throw new Error('invalid operator')
      }
    } else {
      results = await source.getAll()
      slowReason = 'composite indexes not optimized'
    }

    const filteredResults = filter(results, rootNode)

    if (slowReason) {
      const end = Date.now()
      this._logger.warn(`executed a slow query (${slowReason}) that took ${end-start} ms for`, modelDef.name, rootNode)
    }

    return filteredResults
  }

  /**
   * @param {DBTable} table
   * @param {ModelDefinition} modelDef
   * @param {Where} where
   * @param {Order} orderBy
   * @param {number} [skip]
   * @param {number} [limit]
   * @returns {Promise<{results:object[], total:number}>}
   * @private
   */
  async _findGeneric (table, modelDef, where, orderBy, { skip, limit }) {
    const rootNode = where.rootNode()
    const start = Date.now()
    const allRecords = await table.getAll()
    const filteredRecords = filter(allRecords, rootNode)
    const end = Date.now()
    this._logger.warn('executing a slow query (not using any index) that took', end - start, 'ms for',
      modelDef.name, rootNode)
    const orders = orderBy.getOrders()
    return this._sortAndPage(filteredRecords, orders, { skip, limit })
  }

  _sortAndPage (filteredRecords, orders, { skip, limit }) {
    let results
    if (orders.length > 0) {
      results = this._order(filteredRecords, orders)
    } else {
      results = filteredRecords
    }
    if (typeof skip === 'number') {
      results = results.slice(skip)
    }
    if (typeof limit === 'number') {
      results = results.slice(0, limit)
    }
    return { results, total: filteredRecords.length }
  }

  _getIndexValue (index, payload) {
    if (index.length === 1) {
      return payload[index[0]]
    }
    return index.map(x => payload[x])
  }

  _indexHasBoolean (modelDef, index) {
    for (const key of index) {
      if (modelDef.schema[key].type === 'boolean') return true
    }
    return false
  }

  _getKeyValue (modelDef, payload) {
    return this._getIndexValue(modelDef.primaryKey, payload)
  }

  _order (rows, orders) {
    if (!orders || !orders.length) return
    return rows.sort((a, b) => {
      for (const order of orders) {
        const [field, direction] = order
        if (b[field] === null || a[field] < b[field]) {
          return -1 * direction
        }
        if (a[field] === null || a[field] > b[field]) {
          return 1 * direction
        }
      }
      return 0
    })
  }
}

function keysAreIdentical (key1, key2) {
  if (Array.isArray(key1)) {
    for (let i = 0; i < key1.length; i++) {
      if (key1[i] !== key2[i]) return false
    }
    return true
  }
  return key1 === key2
}

export default Mapper
