import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loginUser } from '../actions/auth'
import { Form, Button } from 'react-bootstrap';
import './LoginPage.css';
import {setPageTitle} from "../actions/nav";

export class LoginPage extends Component {
  state = {
    username: '',
    password: ''
  };

  componentDidMount() {
    this.props.setPageTitle('Instructor Login');
  }

  setFormValue = (evt) => this.setState({[evt.target.name]: evt.target.value});

  onSubmit = async (evt) => {
    evt.preventDefault();

    const {loginUser, history} = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } };

    const {username, password} = this.state;
    try {
      await loginUser({ username, password });
      history.push(from);
    } catch (err) {
      //nothing
    }

    return false;
  };

  render () {
    return (
      <div id="LoginPage">
        <Form onSubmit={this.onSubmit}>
          <Form.Group>
            <div className="error">{this.props.loginError}</div>
          </Form.Group>
          <Form.Group>
              <Form.Control autoFocus type="text" name="username" placeholder="username" value={this.state.username} onChange={this.setFormValue} />
          </Form.Group>
          <Form.Group>
              <Form.Control type="password" name="password" placeholder="password" value={this.state.password} onChange={this.setFormValue} />
          </Form.Group>
          <Button variant="primary" type="submit">Login</Button>
        </Form>
      </div>

    )
  }
}

const mapStateToProps = state => ({
  loginError: state.auth.loginError,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  loginUser,
  setPageTitle,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
