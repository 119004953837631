import { remoteCommandWithLocalFallback } from '../utils/api'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED'
export const loginUser = remoteCommandWithLocalFallback(`v1/auth/token`, LOGIN_USER, LOGIN_USER_SUCCESS, LOGIN_USER_FAILED, { extraData: { grant_type: 'password' } })

export const LOGOUT_USER = 'LOGOUT_USER'
export function logoutUser() {
  return {type: LOGOUT_USER}
}
