import wireUp from "./infra";
import esBootstrap from "./infra/indexeddb-es";
//import storageBootstrap from "./infra/memdb/bootstrap";
import storageBootstrap from "./infra/indexeddb-db";
import ConsoleLogger from "./infra/loggers/ConsoleLogger";
import eventsFactories from "./events";
import readModels from "./readModels";
import controllersFactories from "./controllers";
import servicesBootstrap from "./services/bootstrap";

let instance;
let setupPromise;

export default async function factory() {
  if (instance) return instance;
  if (!setupPromise) setupPromise = setup();
  return setupPromise;
}

async function setup() {
  const logger = new ConsoleLogger();
  const config = {
    eventStore: {
      name: 'indexeddb-es'
    }
  };
  const extraServices = {
    buildAppRouter,
  }

  const routes = [];

  function buildAppRouter(name, version) {
    return new AppRouter(name, version, routes);
  }

  const services = await wireUp({
    config,
    logger,
    esBootstrap,
    storageBootstrap,
    readModels,
    eventFactory,
    controllersFactories,
    servicesBootstrap,
    initWeb,
    services: extraServices
  });

  return new API(routes, services.findUser, logger);
}

function initWeb(services, controllersFactories) {
  for(const controllerFactory of controllersFactories) {
    new controllerFactory(services);
  }
}

/**
 * @param {string} eventType
 * @param {object} payload
 * @return {object}
 */
function eventFactory(eventType, payload) {
  const factory = eventsFactories[eventType];
  if (!factory) throw new Error(`Not event factory registered for ${eventType}`);
  return factory(payload);
}

const HTTP_DEFAULT_STATUSES = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  409: 'Conflict',
  422: 'Unprocessable Entity',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  503: 'Service Unavailable'
};

/**
 * @property {number} status
 * @property {string|object} data
 */
class APIResponse {
  /**
   * @param {number} status
   * @param {string|object} data
   * @param {string} statusText
   */
  constructor(status, data, statusText = '') {
    this.status = status;
    this.statusText = statusText || HTTP_DEFAULT_STATUSES[status] || 'TODO';
    this.data = data;
    Object.freeze(this);
  }
}

class AppRouter {
  constructor(name, version, routes) {
    this._name = name;
    this._version = version;
    this._routes = routes;
  }

  command(TCommand, handler) {
    let name = '';
    if (typeof(TCommand) === 'string') name = TCommand;
    if (typeof(TCommand.type) === 'string') name = TCommand.type;
    if (name === '') throw new TypeError('TCommand must be a string or have a string "type" property')
    this._routes.push({
      path: `v${this._version}/${this._name}/${name}`,
      handler,
    });
  }

  findOne(queryName, handler) {
    this._routes.push({
      path: `v${this._version}/${this._name}/${queryName}`,
      handler,
    });
  }

  findByFilter(queryName, handler) {
    this._routes.push({
      path: `v${this._version}/${this._name}/${queryName}`,
      handler,
    });
  }

  ok(data = {}) {
    return new APIResponse(200, data);
  }

  created(data = {}) {
    return new APIResponse(201, data);
  }

  badRequest(data = {}) {
    return new APIResponse(400, data);
  }

  unauthorized(data = {}) {
    return new APIResponse(401, data);
  }

  forbidden(data = {}) {
    return new APIResponse(403, data);
  }

  notFound(data = {}) {
    return new APIResponse(404, data);
  }

  conflict(data = {}) {
    return new APIResponse(409, data);
  }

  error(data = {}) {
    return new APIResponse(500, data);
  }
}

class API {
  constructor(routes, findUser, logger) {
    this._logger = logger;
    this._routes = routes;
    this._findUser = findUser;
  }

  _getUserFromHeaders(headers) {
    if (typeof(headers) !== 'object' || headers === null) return null;
    // We can trust the X-UserId header because it's local
    const xUserId = headers['X-UserId'];
    return this._findUser({userId: xUserId});
  }

  async command(uri, payload = {}, options = {}) {
    const {headers} = options;
    const route = this._findMatchingRoute(uri);
    let res;
    try {
      const user = await this._getUserFromHeaders(headers);
      res = await route.handler({ user, body: payload, headers });
    } catch (err) {
      res = handleError(this._logger, err);
    }
    return handleResult(res, {method: 'POST', url: uri, data: payload}, 202);
  }

  async find(uri, payload = {}, options = {}) {
    const {headers} = options;
    const route = this._findMatchingRoute(uri);
    let res;
    try {
      const user = await this._getUserFromHeaders(headers);
      res = await route.handler({ user, query: payload, headers });
    } catch (err) {
      res = handleError(this._logger, err);
    }
    return handleResult(res, {method: 'GET', url: uri});
  }

  async findOne(uri, payload = {}, options = {}) {
    const {headers} = options;
    const route = this._findMatchingRoute(uri);
    let res;
    try {
      const user = await this._getUserFromHeaders(headers);
      res = await route.handler({ user, query: payload, headers });
    } catch (err) {
      res = handleError(this._logger, err);
    }
    return handleResult(res, {method: 'GET', url: uri});
  }

  _findMatchingRoute(uri) {
    const route = this._routes.find(x => x.path === uri);
    if (!route) {
      const err =  new Error("404 not found");
      err.code = 'NotFound';
      throw err;
    }
    return route;
  }
}

function handleResult(res, req, defaultStatus = 200) {
  if (!(res instanceof APIResponse)) res = new APIResponse(defaultStatus, res);
  if (res.status >= 400) {
    const err = new Error(`API Request failed: ${req.method} ${req.url} returned ${res.status} ${res.statusText}`);
    err.response = res;
    err.request = req;
    throw err;
  }
  return res;
}

function handleError(logger, err) {
  if (err.code === "NotFound") {
    logger.warn(err);
    return new APIResponse(404, {message: err.message});
  } else if (["ValidationFailed", "TypeError"].includes(err.name)) {
    logger.warn(err);
    return new APIResponse(400, {message: err.message, validationErrors: err.validationErrors});
  } else {
    logger.error(err);
    return new APIResponse(500, {message: err.message})
  }
}
