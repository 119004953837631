import uuid from'uuid';
import AddSkillOption from "../commands/AddSkillOption";
import SkillOption from "../domain/SkillOption";

export default class SkillOptionController {
  /**
   * @param buildAppRouter
   * @param readRepository
   * @param {commandHandler} commandHandler
   */
  constructor({buildAppRouter, readRepository, commandHandler}) {
    this._router = buildAppRouter('skillOption', 1);
    this._readRepository = readRepository;
    this._commandHandler = commandHandler;

    this._router.command(AddSkillOption, this.AddSkillOption.bind(this));
    this._router.findByFilter('find', this.find.bind(this));
  }

  async AddSkillOption({user, body}) {
    if (!user) return this._router.unauthorized();
    const {name, globalSkillId} = body;
    const cmd = new AddSkillOption(uuid.v4(), globalSkillId, name);
    await this._commandHandler(SkillOption, cmd.skillOptionId, cmd);
    return(cmd.skillOptionId);
  }

  async find({user, query}) {
    if (!user) return this._router.unauthorized();
    return this._readRepository.findByFilter('skillOptions', query);
  }
}
