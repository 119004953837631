export default class SkillOptionAdded {
  static type = 'SkillOptionAdded';

  /**
   * @param {!string} skillOptionId
   * @param {!string} globalSkillId
   * @param {!string} name
   */
  constructor(skillOptionId, globalSkillId, name) {
    if (typeof skillOptionId !== 'string') throw new TypeError('skillOptionId must be a string');
    if (typeof globalSkillId !== 'string') throw new TypeError('globalSkillId must be a string');
    if (typeof name !== 'string') throw new TypeError('name must be a string');
    this.skillOptionId = skillOptionId;
    this.globalSkillId = globalSkillId;
    this.name = name;
  }
}
