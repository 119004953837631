import {deepClone, getTypeName} from "./utils";

export class Aggregate {
  constructor() {
    this._commandHandlers = {};
    this._eventHandlers = {};
    this._state = {};
  }

  _registerCommandHandler(TCommand, handler) {
    this._commandHandlers[TCommand.type] = handler;
  }

  _registerEventHandler(TEvent, handler) {
    this._eventHandlers[TEvent.type] = handler;
  }

  /**
   * @param {object} event
   */
  hydrate(event) {
    const handler = this._eventHandlers[getTypeName(event)];
    if (handler) this._state = handler(Object.freeze(this._state), event);
  }

  /**
   * @param {object} command
   * @returns {object[]}
   */
  execute(command) {
    const handler = this._commandHandlers[getTypeName(command)];
    if (handler) return handler(Object.freeze(this._state), command);
    throw new Error(`Unknown command "${getTypeName(command)}" for ${getTypeName(this)}`);
  }

  /**
   * @param {object} memento
   */
  restoreFromMemento(memento) {
    for (const k in memento) {
      this._state[k] = deepClone(memento[k]);
    }
  }

  /**
   * @returns {object}
   */
  createMemento() {
    return deepClone(this._state);
  }
}
