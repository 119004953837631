import uuid from'uuid';
import AddManeuver from "../commands/AddManeuver";
import Maneuver from "../domain/Maneuver";

export default class ManeuverController {
  /**
   * @param buildAppRouter
   * @param readRepository
   * @param {commandHandler} commandHandler
   */
  constructor({buildAppRouter, readRepository, commandHandler}) {
    this._router = buildAppRouter('maneuver', 1);
    this._readRepository = readRepository;
    this._commandHandler = commandHandler;

    this._router.command(AddManeuver, this.addManeuver.bind(this));
    this._router.findByFilter('find', this.find.bind(this));
    this._router.findOne('findOne', this.findOne.bind(this));
  }

  async addManeuver({user, body}) {
    if (!user) return this._router.unauthorized();
    const {name} = body;
    const cmd = new AddManeuver(uuid.v4(), name);
    await this._commandHandler(Maneuver, cmd.maneuverId, cmd);
    return(cmd.maneuverId);
  }

  async find({user, query}) {
    if (!user) return this._router.unauthorized();
    return this._readRepository.findByFilter('maneuvers', query);
  }

  async findOne({user, query}) {
    if (!user) return this._router.unauthorized();
    return this._readRepository.findOne('maneuvers', query);
  }
}
