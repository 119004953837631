export default class StudentRegistered {
  static type = 'StudentRegistered';
  /**
   * @param {!string} studentId
   * @param {!string} name
   * @param {!string} licenseNo
   * @param {!string} phoneNo
   * @param {!string} email
   * @param {!string} testDate
   * @param {!string} testTime
   * @param {!string} testLocation
   * @param {!string} emergencyContactNo
   * @param {!string} emergencyContactName
   * @param {!string} allergies
   */
  constructor(studentId, name, licenseNo, phoneNo, email, testDate, testTime, testLocation, emergencyContactNo, emergencyContactName, allergies) {
    if (typeof studentId !== 'string') throw new TypeError("studentId must be a string");
    if (typeof name !== 'string') throw new TypeError("name must be a string");
    if (typeof licenseNo !== 'string') throw new TypeError("licenseNo must be a string");
    if (typeof phoneNo !== 'string') throw new TypeError("phoneNo must be a string");
    if (typeof email !== 'string') throw new TypeError("email must be a string");
    if (typeof testDate !== 'string') throw new TypeError("testDate must be a string");
    if (typeof testTime !== 'string') throw new TypeError("testTime must be a string");
    if (typeof testLocation !== 'string') throw new TypeError("testLocation must be a string");
    if (typeof emergencyContactNo !== 'string') throw new TypeError("emergencyContactNo must be a string");
    if (typeof emergencyContactName !== 'string') throw new TypeError("emergencyContactName must be a string");
    if (typeof allergies !== 'string') throw new TypeError("allergies must be a string");
    this.studentId = studentId;
    this.name = name;
    this.licenseNo = licenseNo;
    this.phoneNo = phoneNo;
    this.email = email;
    this.testDate = testDate;
    this.testTime = testTime;
    this.testLocation = testLocation;
    this.emergencyContactNo = emergencyContactNo;
    this.emergencyContactName = emergencyContactName;
    this.allergies = allergies;
  }
}
