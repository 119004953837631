import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux'
import { Button, Modal, Spinner } from 'react-bootstrap'
import {syncFromServer, syncToServer, getSyncInfo, resetDevice} from '../actions/sync';
import {setPageTitle} from "../actions/nav";
import './SyncPage.css';

const oneMinute = 60*1000;
const oneHour = 60*oneMinute;
const oneDay = 24*oneHour;
function formattedDiff(ts) {
  if (ts === 0) return 'N/A';
  const diff = Date.now() - ts;
  if (diff > oneDay) // days
    return `${Math.floor(diff/oneDay)}d`;
  if (diff > oneHour) // hours
    return `${Math.floor(diff/oneHour)}h`;
  return `${Math.floor(diff/oneMinute)}m`;
}

export class SyncPage extends Component {
  static propTypes = {
    // state
    auth: PropTypes.object.isRequired,
    syncInProgress: PropTypes.bool.isRequired,
    // actions
    setPageTitle: PropTypes.func.isRequired,
    getSyncInfo: PropTypes.func.isRequired,
    syncToServer: PropTypes.func.isRequired,
    syncFromServer: PropTypes.func.isRequired,
  };

  state = {
    showResetDeviceConfirm: false
  };

  componentDidMount() {
    this.props.setPageTitle('Sync with server');
    this.syncInfo();
  }

  async syncInfo() {
    try {
      await this.props.getSyncInfo();
    } catch (err) {
      console.error(err);
    }
  }

  syncFromServer = async() => {
    try {
      await this.props.syncFromServer();
      await this.syncInfo();
    } catch (err) {
      console.error(err);
    }
  };

  syncToServer = async() => {
    try {
      await this.props.syncToServer();
      await this.syncInfo();
    } catch (err) {
      console.error(err);
    }
  };

  confirmResetDevice = () => {
    this.setState({showResetDeviceConfirm: true})
  };

  hideResetDevice = () => {
    this.setState({showResetDeviceConfirm: false})
  };

  resetDevice = async() => {
    try {
      await this.props.resetDevice();
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const {
      auth, syncError, deviceId, lastFetchedEventTimestamp, lastPushTimestamp,
      syncInProgress, fetchInProgress, pushInProgress
    } = this.props;
    const { showResetDeviceConfirm } = this.state;

    if (!auth.hasRole("admin")) {
      return <div/>;
    }

    return <div id="SyncPage">
      <div className="error">{syncError}</div>
      <div style={{fontSize: '11pt'}}>DeviceID: {deviceId || 'N/A'}</div>
      <div>
        <Button onClick={this.confirmResetDevice}>Reset Device</Button>
      </div>
      <div>
        Last event from server: {formattedDiff(lastFetchedEventTimestamp)} ago
        <br/>
        <Button disabled={syncInProgress} onClick={this.syncFromServer}>
          {fetchInProgress && <Spinner animation="border" size="sm"/>}
          Sync from server
        </Button>
      </div>
      <div>
        Last push to server: {formattedDiff(lastPushTimestamp)} ago
        <br/>
        <Button disabled={syncInProgress} onClick={this.syncToServer}>
          {pushInProgress && <Spinner animation="border" size="sm"/>}
          Sync to server
        </Button>
      </div>
      <Modal show={showResetDeviceConfirm} onHide={this.hideResetDevice}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Device</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-warning">Warning: Potential data loss.</p>
          <p>Make sure you pushed everything you want to keep to the server first.</p>
          <p>You'll also have to re-login and sync from the server after the reset.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.hideResetDevice} variant="secondary">Cancel</Button>
          <Button onClick={this.resetDevice} variant="primary">Proceed</Button>
        </Modal.Footer>
      </Modal>
    </div>
  }
}

const mapStateToProps = state => ({
  ...state.sync,
  auth: state.auth.auth,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  getSyncInfo,
  syncToServer,
  syncFromServer,
  resetDevice,
  setPageTitle,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SyncPage);
