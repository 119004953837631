import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./store";
import API from "./cqrs";
import { INIT_STATE } from './actions/init';

const stateKey = 'raincity_state';

// start API async
(async function() {
  // load auth from session storage, so that we don't loose auth when refreshing the page
  try {
    const state = JSON.parse(window.sessionStorage.getItem(stateKey));
    if (state) store.dispatch({type: INIT_STATE, payload: state});
  } catch (err) {}
  store.subscribe(() => {
    const state = store.getState();
    if (state) {
      window.sessionStorage.setItem(stateKey, JSON.stringify(state));
    }
  });

  try {
    await API()
  } catch (err) {
    //TODO push to notification framework
    console.error("Error while starting the frontend API:", err);
  }
})();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => store.dispatch({type: 'NEW_APP_CONTENT_AVAILABLE'})
});
