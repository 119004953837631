import * as users from './users';
import * as instructors from './instructors';
import * as students from './students';
import * as lessons from './lessons';
import * as maneuvers from './maneuvers';
import * as sync from './sync';
import * as globalSkills from "./globalSkills";
import * as skillOptions from "./skillOptions";
import * as maneuverTypes from "./maneuverTypes";
import * as assessments from "./assessments";

const readModels = {
  users,
  instructors,
  students,
  lessons,
  maneuvers,
  sync,
  globalSkills,
  skillOptions,
  maneuverTypes,
  assessments,
};

export default readModels;
