//TODO real transaction
export class Transaction {
  constructor(conn) {
    this._promises = [];
    this._errors = [];
    this._connection = conn;
  }
  add(promise) {
    this._promises.push(promise.catch(err => this._errors.push(err)));
  }
  /**
   * @async
   */
  commit() {
    return Promise.all(this._promises)
      .then(() => {
        if (this._errors.length) throw this._errors[0];
      });
  }
  get connection() {
    return this._connection;
  }
}

export default Transaction;

/*
Real Transaction will require more than just accumulating actions

Postgres needs to build a real SQL transaction
  - begin: BEGIN TRANSACTION
  - add: sql statement
  - commit: COMMIT
LevelDB
  - begin: create empty batch
  - add: enqueue operations in batch
  - commit: execute batch
InMemory
  - begin: lock on the read model
  - add: enqueue actions
  - commit: executes actions and release lock
*/
