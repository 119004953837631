import {
  SYNC_FROM_SERVER,
  SYNC_FROM_SERVER_FAILED,
  SYNC_FROM_SERVER_SUCCESS, SYNC_GET_INFO_SUCCESS, SYNC_RESET_DEVICE_SUCCESS,
  SYNC_TO_SERVER, SYNC_TO_SERVER_FAILED,
  SYNC_TO_SERVER_SUCCESS
} from '../actions/sync';

const defaultState = {
  deviceId: '',
  lastFetchedEventTimestamp: 0,
  lastPushTimestamp: 0,
  syncInProgress: false,
  fetchInProgress: false,
  pushInProgress: false,
  syncError: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SYNC_FROM_SERVER: {
      return {...state, fetchInProgress: true, syncInProgress: true, syncError: null};
    }
    case SYNC_FROM_SERVER_SUCCESS: {
      return {...state, fetchInProgress: false, syncInProgress: false};
    }
    case SYNC_FROM_SERVER_FAILED: {
      return {...state, fetchInProgress: false, syncInProgress: false, syncError: errorMessage(action.payload)};
    }
    case SYNC_TO_SERVER: {
      return {...state, pushInProgress: true, syncInProgress: true, syncError: null};
    }
    case SYNC_TO_SERVER_SUCCESS: {
      return {...state, pushInProgress: false, syncInProgress: false};
    }
    case SYNC_TO_SERVER_FAILED: {
      return {...state, pushInProgress: false, syncInProgress: false, syncError: errorMessage(action.payload)};
    }
    case SYNC_GET_INFO_SUCCESS: {
      const {deviceId, lastFetchedEventTimestamp, lastPushTimestamp} = action.payload;
      return {...state, deviceId, lastFetchedEventTimestamp, lastPushTimestamp};
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}

function errorMessage(err) {
  if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message;
  }
  return err.message;
}
