import {errorMessageFromPayload} from "../utils/errors";
import {GET_ASSESSMENTS, GET_ASSESSMENTS_FAILED, GET_ASSESSMENTS_SUCCESS} from "../actions/assessment";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  list: [],
  loading: false,
  error: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_ASSESSMENTS: {
      return {...state, loading: true, error: ''}
    }
    case GET_ASSESSMENTS_SUCCESS: {
      return {...state, loading: false, list: action.payload};
    }
    case GET_ASSESSMENTS_FAILED: {
      return {...state, loading: false, error: errorMessageFromPayload(action.payload)};
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
