export const config = {
    key: 'lessonId',
    indexes: ['studentId'],
    schema: {
        lessonId: {type: 'string', format: 'uuid', nullable: false},
        studentId: {type: 'string', format: 'uuid', nullable: false},
        instructorId: {type: 'string', format: 'uuid', nullable: false},
        lessonDateTime: {type: 'string', nullable: false},
        completed: {type: 'boolean', nullable: false}
    }
};

export async function handler(lessons, eventData) {
    const {typeId, event} = eventData;
    switch (typeId) {
        case 'LessonStarted': {
            const {lessonId, instructorId, studentId, lessonDateTime} = event;
            lessons.create({lessonId, instructorId, studentId, lessonDateTime, completed: false});
            break;
        }
        case 'LessonCompleted': {
            const {lessonId} = event;
            lessons.updateOne({lessonId}, {completed: true});
            break;
        }
        default:
            break;
    }
    return lessons;
}
