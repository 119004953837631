import * as base from '../EventStore';

/**
 * @class
 * @property {number} value
 */
export default class Position extends base.EventStorePosition {
  /**
   * @param {number} position
   */
  constructor(position) {
    super();
    if (typeof position !== 'number') throw new TypeError("position must be a number");
    this.value = position;
    Object.freeze(this);
  }
  /**
   * @param {Position} other
   * @returns {number}
   */
  compareTo(other) {
    if (!(other instanceof Position)) throw new TypeError("other must be a Position");
    return this.value - other.value;
  }

  toString() {
    return "" + this.value;
  }
}
