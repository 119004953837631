import StartLesson from "../commands/StartLesson";
import Lesson from "../domain/Lesson";
import moment from 'moment';
import uuid from'uuid';
import CompleteLesson from "../commands/CompleteLesson";

export default class LessonController {
    /**
     * @param buildAppRouter
     * @param readRepository
     * @param {commandHandler} commandHandler
     */
    constructor({buildAppRouter, readRepository, commandHandler}) {
        this._router = buildAppRouter('lesson', 1);

        this._router.command(StartLesson, async({user, body}) => {
            if (!user) return this._router.unauthorized();
            const now = moment().toISOString();
            const cmd = new StartLesson(uuid.v4(), body.instructorId, body.studentId, now);
            await commandHandler(Lesson, cmd.lessonId, cmd);
            return(cmd.lessonId);
        });

        this._router.command(CompleteLesson, async({user, body}) => {
            if (!user) return this._router.unauthorized();
            const {lessonId, timestamp, signature} = body;
            const cmd = new CompleteLesson(lessonId, timestamp, signature);
            await commandHandler(Lesson, cmd.lessonId, cmd);
            return(cmd.lessonId);
        });

        this._router.findByFilter('find', async({user, query}) => {
            if (!user) return this._router.unauthorized();
            return (await readRepository.findByFilter('lessons', query));
        });
    }
}
