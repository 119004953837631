export default class ManeuverTypeAdded {
  static type = 'ManeuverTypeAdded';

  /**
   * @param {!string} maneuverTypeId
   * @param {!string[]} maneuverIds
   * @param {!string} name
   */
  constructor(maneuverTypeId, maneuverIds, name) {
    if (typeof maneuverTypeId !== 'string') throw new TypeError("maneuverTypeId must be a string");
    if (!Array.isArray(maneuverIds) || maneuverIds.some(x => typeof x !== 'string')) throw new TypeError("maneuverIds must be an array of strings");
    if (typeof name !== 'string') throw new TypeError("name must be a string");
    this.maneuverTypeId = maneuverTypeId;
    this.maneuverIds = maneuverIds;
    this.name = name;
  }
}