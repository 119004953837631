import {Aggregate} from "../infra/aggregate";
import AddManeuver from "../commands/AddManeuver";
import ManeuverAdded from "../events/ManeuverAdded";
import {ValidationFailed} from "./errors";

export default class Maneuver extends Aggregate {
  constructor() {
    super();

    this._registerCommandHandler(AddManeuver, Maneuver.addManeuver);
    this._registerEventHandler(ManeuverAdded, Maneuver.onManeuverAdded);
  }

  static addManeuver(state, command) {
    const validationErrors = [];
    if (command.maneuverId === '') validationErrors.push({field: 'maneuverId', message: 'is required'});
    if (command.name === '') validationErrors.push({field: 'name', message: 'is required'});
    if (validationErrors.length) throw new ValidationFailed(validationErrors);

    if (state.maneuverId) throw new ValidationFailed('Maneuver already added');

    return [
      new ManeuverAdded(command.maneuverId, command.name)
    ];
  }

  static onManeuverAdded(state, event) {
    const {maneuverId} = event;
    return {...state, maneuverId};
  }
}