import InstructorAdded from './InstructorAdded';
import StudentRegistered from './StudentRegistered';
import LessonStarted from './LessonStarted';
import LessonCompleted from "./LessonCompleted";
import SkillAssessed from "./SkillAssessed";
import ManeuverAdded from "./ManeuverAdded";
import SkillOptionAdded from "./SkillOptionAdded";
import InstructorLoggedIn from './InstructorLoggedIn'
import DeviceAdded from './DeviceAdded'
import GlobalSkillAdded from "./GlobalSkillAdded";
import ManeuverTypeAdded from "./ManeuverTypeAdded";

const events = {};

function registerEventFactory(TEvent, factory) {
  events[TEvent.type] = factory;
}

registerEventFactory(InstructorAdded, ({userId, firstName, lastName, email, password}) => new InstructorAdded(userId, firstName, lastName, email, password));
registerEventFactory(StudentRegistered, ({studentId, name, licenseNo, phoneNo, email, testDate, testTime, testLocation, emergencyContactNo, emergencyContactName, allergies}) => new StudentRegistered(studentId, name, licenseNo, phoneNo, email, testDate, testTime, testLocation, emergencyContactNo, emergencyContactName, allergies));
registerEventFactory(LessonStarted, ({lessonId, instructorId, studentId, lessonDateTime}) => new LessonStarted(lessonId, instructorId, studentId, lessonDateTime));
registerEventFactory(LessonCompleted, ({lessonId, timestamp, signature}) => new LessonCompleted(lessonId, timestamp, signature));
registerEventFactory(SkillAssessed, ({assessmentId, lessonId, maneuverId, globalSkillId, skillOptionId, maneuverTypeId, timestamp}) => new SkillAssessed(assessmentId, lessonId, maneuverId, globalSkillId, skillOptionId, maneuverTypeId, timestamp));
registerEventFactory(ManeuverAdded, ({maneuverId, name}) => new ManeuverAdded(maneuverId, name));
registerEventFactory(SkillOptionAdded, ({skillOptionId, globalSkillId, name}) => new SkillOptionAdded(skillOptionId, globalSkillId, name));
registerEventFactory(InstructorLoggedIn, ({instructorId}) => new InstructorLoggedIn(instructorId));
registerEventFactory(DeviceAdded, ({deviceId}) => new DeviceAdded(deviceId));
registerEventFactory(GlobalSkillAdded, ({globalSkillId, name, isIndependent, isError}) => new GlobalSkillAdded(globalSkillId, name, isIndependent, isError));
registerEventFactory(ManeuverTypeAdded, ({maneuverTypeId, maneuverIds, name}) => new ManeuverTypeAdded(maneuverTypeId, maneuverIds, name));

export default events;