import bcrypt from "bcryptjs";

/**
 * Hash a password using bcrypt
 * @param {string} password
 * @param {?string} useSaltFrom Existing password hash to extract salt from
 * @return {Promise<string>}
 */
export async function hashPassword(password, useSaltFrom = null) {
  const salt = (typeof useSaltFrom === 'string' && useSaltFrom.substr(0, 29)) ||
    await bcrypt.genSalt();
  return await bcrypt.hash(password, salt);
}
