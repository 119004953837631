export class TransactionalRepository {
  constructor(mapper, modelName, readRepository, trx, logger) {
    this._mapper = mapper;
    this._modelName = modelName;
    this._logger = logger;
    this._trx = trx;
    this._readRepository = readRepository;
  }

  /**
   * Create an entity using payload
   * @param {object} payload
   * Note: this actually does an upsert, so save would be a better name
   */
  create(payload) {
    this._trx.add(this._mapper.upsert(this._trx.connection, this._modelName, payload));
  }

  /**
   * Update one entity matching constraints with changes
   * @param {object} where
   * @param {object} changes
   */
  updateOne(where, changes) {
    if (typeof changes !== 'object') {
      throw new TypeError('changes must be an object');
    }
    return this._updateStatic(where, changes);
  }

  /**
   * Update multiple entities matching constraints with the same changes
   * @param {object} where
   * @param {object} changes
   */
  updateWhere(where, changes) {
    return this._updateStatic(where, changes);
  }

  /**
   * @param {object} where
   */
  remove(where) {
    this._trx.add(this._mapper.remove(this._trx.connection, this._modelName, where));
  }

  /**
   * @param {object} where
   * @param {boolean} [noThrowOnNotFound]
   */
  findOne(where, noThrowOnNotFound) {
    return this._readRepository.findOne(this._modelName, where, noThrowOnNotFound);
  }

  /**
   * @param {object} where
   */
  findWhere(where) {
    return this._readRepository.findWhere(this._modelName, where);
  }

  /**
   * @param filter
   * @returns {Promise<ReadResult|Object[]>}
   */
  findByFilter(filter) {
    return this._readRepository.findByFilter(this._modelName, filter);
  }

  /**
   * Find all entities
   * @returns {Promise<Object[]>}
   */
  findAll() {
    return this._readRepository.findAll(this._modelName);
  }

  /**
   * @param {object} where
   * @return {Promise<boolean>}
   */
  exists(where) {
    return this._readRepository.exists(this._modelName, where);
  }

  _updateStatic(where, data) {
    this._trx.add(this._mapper.update(this._trx.connection, this._modelName, data, where));
  }
}
export default TransactionalRepository;

/**
 * @callback updateOneCallback
 * @param {object} data
 * @returns {object} modified data
 */
