export function errorMessageFromPayload(payload) {
  if (payload.response && payload.response.data && payload.response.data.message) {
    return transformIfArray(payload.response.data.message);
  }
  return payload.message;
}

function transformIfArray(message) {
  if (Array.isArray(message)) {
    return message.map(x => `${x.field} ${x.message}`).join()
  }
  return message;
}
