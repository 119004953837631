export const config = {
  key: ['deviceId'],
  schema: {
    deviceId: {type: 'string', format: 'uuid', nullable: false},
    lastFetchedPosition: {type: 'number', nullable: false},
    lastFetchedEventTimestamp: {type: 'number', nullable: false},
    lastPushedPosition: {type: 'number', nullable: false},
    lastPushTimestamp: {type: 'number', nullable: false}
  }
};

export async function handler(repo, eventData) {
  const {typeId, event, metadata} = eventData;

  switch (typeId) {
    case 'DeviceAdded': {
      const {deviceId} = event;
      repo.create({deviceId, lastFetchedPosition: 0, lastFetchedEventTimestamp: 0, lastPushedPosition: 0, lastPushTimestamp: 0});
      break;
    }
    case 'SyncEventsPushed': {
      const {lastPosition} = event;
      const {timestamp} = metadata;
      repo.updateOne({}, {lastPushedPosition: lastPosition, lastPushTimestamp: timestamp});
      break;
    }
    default: {
      const {serverPosition, timestamp} = metadata;
      if (typeof serverPosition !== 'number') break;
      repo.updateOne({}, {lastFetchedPosition: serverPosition, lastFetchedEventTimestamp: timestamp});
    }
  }

  return repo;
}
