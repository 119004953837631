import commandHandlerFactory from "./commandHandler";
import {AggregateCache} from "./aggregateCache";
import {SnapshotStore} from "./snapshot";
import CommandActionHelper from "./CommandActionHelper";

/**
 * Initialize CQRS write side
 * @param {Object} services Services registry
 * @param {eventFactory} eventFactory
 * @returns {Promise}
 */
export default async function initWrite(services, eventFactory) {
  const {config, eventStoreWithConversion, readRepository, logger} = services;
  const aggregateCache = new AggregateCache();
  const snapshotStore = new SnapshotStore();
  const commandHandler = commandHandlerFactory(config, eventFactory, eventStoreWithConversion, aggregateCache, snapshotStore, logger);
  const commandActionHelper = new CommandActionHelper(commandHandler, readRepository, logger);
  Object.assign(services, {eventFactory, commandHandler, commandHandlerFactory, commandActionHelper});
}
