import uuid from "uuid";
import RegisterStudent from "../commands/RegisterStudent";
import Student from "../domain/Student";

export default class StudentController {
  constructor({buildAppRouter, commandHandler, readRepository}) {
    this._router = buildAppRouter('student', 1);
    this._router.findOne('findOne', async({user, query}) => {
      if (!user) return this._router.unauthorized();
      const {studentId} = query;

      return (await readRepository.findOne('students', {studentId}));
    });

    this._router.findByFilter('find', async({user, query}) => {
      if (!user) return this._router.unauthorized();

      return (await readRepository.findByFilter('students', {}));
    });

    this._router.command(RegisterStudent, async({user, body}) => {
      if (!user) return this._router.unauthorized();
      const studentId = uuid();
      const {userId} = user;
      const {name, licenseNo, phoneNo, email, testDate, testTime, testLocation, emergencyContactNo, emergencyContactName, allergies} = body;
      const cmd = new RegisterStudent(studentId, name, licenseNo, phoneNo, email, testDate, testTime, testLocation, emergencyContactNo, emergencyContactName, allergies);
      await commandHandler(Student, cmd.studentId, cmd, {userId});
      return ({studentId});
    });
  }
}
