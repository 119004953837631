import uuid from'uuid';
import AddGlobalSkill from "../commands/AddGlobalSkill";
import GlobalSkill from "../domain/GlobalSkill";

export default class GlobalSkillController {
  /**
   * @param buildAppRouter
   * @param readRepository
   * @param {commandHandler} commandHandler
   */
  constructor({buildAppRouter, readRepository, commandHandler}) {
    this._router = buildAppRouter('globalSkill', 1);
    this._readRepository = readRepository;
    this._commandHandler = commandHandler;

    this._router.command(AddGlobalSkill, this.addSkill.bind(this));
    this._router.findByFilter('find', this.find.bind(this));
    this._router.findOne('findOne', this.findOne.bind(this));
  }

  async addSkill({user, body}) {
    if (!user) return this._router.unauthorized();
    const {name, isIndependent, isError} = body;
    const cmd = new AddGlobalSkill(uuid.v4(), name, isIndependent, isError);
    await this._commandHandler(GlobalSkill, cmd.globalSkillId, cmd);
    return(cmd.skillId);
  }

  async find({user, query}) {
    if (!user) return this._router.unauthorized();
    return this._readRepository.findByFilter('globalSkills', query);
  }

  async findOne({user, query}) {
    if (!user) return this._router.unauthorized();
    return this._readRepository.findOne('globalSkills', query);
  }
}
