import {
  GET_STUDENT,
  GET_STUDENT_FAILED,
  GET_STUDENT_SUCCESS,
  REGISTER_STUDENT, REGISTER_STUDENT_FAILED,
  REGISTER_STUDENT_SUCCESS
} from '../actions/students';
import {INIT_STATE} from "../actions/init";
import {errorMessageFromPayload} from "../utils/errors";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  error: '',
  fetching: false,
  registering: false,
  studentId: '',
  name: '',
  licenseNo: '',
  phoneNo: '',
  email: '',
  testDate: '',
  testTime: '',
  testLocation: '',
  emergencyContactNo: '',
  emergencyContactName: '',
  allergies: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case INIT_STATE: {
      return {...state, ...action.payload.student};
    }
    case GET_STUDENT: {
      return {...state, fetching: true};
    }
    case GET_STUDENT_SUCCESS: {
      const {studentId, name, licenseNo, phoneNo, email, testDate, testTime, testLocation, emergencyContactNo, emergencyContactName, allergies} = action.payload;
      return {
        ...state,
        fetching: false,
        error: '',
        studentId,
        name,
        licenseNo,
        phoneNo,
        email,
        testDate,
        testTime,
        testLocation,
        emergencyContactNo,
        emergencyContactName,
        allergies
      };
    }
    case GET_STUDENT_FAILED: {
      return {...state, fetching: false, error: errorMessageFromPayload(action.payload)};
    }
    case REGISTER_STUDENT: {
      const {name, licenseNo, phoneNo, email, testDate, testTime, testLocation, emergencyContactNo, emergencyContactName, allergies} = action.payload;
      return {
        ...state,
        registering: true,
        studentId: 'new',
        name,
        licenseNo,
        phoneNo,
        email,
        testDate,
        testTime,
        testLocation,
        emergencyContactNo,
        emergencyContactName,
        allergies
      };
    }
    case REGISTER_STUDENT_SUCCESS: {
      const {studentId} = action.payload;
      return {...state, registering: false, error: '', studentId};
    }
    case REGISTER_STUDENT_FAILED: {
      return {...state, registering: false, error: errorMessageFromPayload(action.payload)};
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
