import {combineReducers} from "redux";
import auth from './auth';
import sync from './sync';
import instructor from './instructor';
import addInstructor from './addInstructor';
import instructors from './instructors';
import student from './student';
import registerStudent from './registerStudent';
import startLesson from './startLesson';
import students from "./students";
import completeLesson from "./completeLesson";
import assessment from "./assessment";
import addManeuver from "./addManeuver";
import maneuvers from "./maneuvers";
import addGlobalSkill from "./addGlobalSkill";
import globalSkills from "./globalSkills";
import addSkillOption from "./addSkillOption";
import skillOptions from "./skillOptions";
import addManeuverType from "./addManeuverType";
import maneuverTypes from "./maneuverTypes";
import lessons from "./lessons";
import assessments from "./assessments";
import nav from "./nav";
import appUpdate from "./appUpdate";

export default combineReducers({
  auth,
  sync,
  instructor,
  addInstructor,
  instructors,
  student,
  students,
  registerStudent,
  startLesson,
  completeLesson,
  assessment,
  addManeuver,
  maneuvers,
  addGlobalSkill,
  globalSkills,
  addSkillOption,
  skillOptions,
  addManeuverType,
  maneuverTypes,
  lessons,
  assessments,
  nav,
  appUpdate
});
