import {Aggregate} from "../infra/aggregate";
import {ValidationFailed} from "./errors";
import SkillAssessed from "../events/SkillAssessed";
import AssessSkill from "../commands/AssessSkill";

export default class Assessment extends Aggregate {
    constructor(){
        super();

        this._registerCommandHandler(AssessSkill, Assessment.assessSkill);
        this._registerEventHandler(SkillAssessed, Assessment.onSkillAssessed);
    }

    static async assessSkill(state, command) {
        const validationErrors = [];
        if (command.assessmentId === '') validationErrors.push({field: 'assessmentId', message: 'is required'});
        if (command.lessonId === '') validationErrors.push({field: 'lessonId', message: 'is required'});
        if (command.maneuverId === '') validationErrors.push({field: 'maneuverId', message: 'is required'});
        if (command.globalSkillId === '') validationErrors.push({field: 'globalSkillId', message: 'is required'});
        if (command.skillOptionId === '') validationErrors.push({field: 'skillOptionId', message: 'is required'});
        if (command.maneuverTypeId === '') validationErrors.push({field: 'maneuverTypeId', message: 'is required'});
        if (command.timestamp === '') validationErrors.push({field: 'timestamp', message: 'is required'});
        if (validationErrors.length) throw new ValidationFailed(validationErrors);

        if (state.assessmentId) throw new ValidationFailed('Assessment has already been assessed');

        return [
            new SkillAssessed(command.assessmentId, command.lessonId, command.maneuverId, command.globalSkillId, command.skillOptionId, command.maneuverTypeId, command.timestamp),
        ];
    }

    static onSkillAssessed(state, event) {
        return {...state, assessmentId: event.assessmentId};
    }
}