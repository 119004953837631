import { Aggregate } from '../infra/aggregate';
import {ValidationFailed} from './errors';
import RegisterStudent from '../commands/RegisterStudent';
import StudentRegistered from '../events/StudentRegistered';

export default class Student extends Aggregate {
  constructor() {
    super();
    this._registerCommandHandler(RegisterStudent, Student.registerStudent);
    this._registerEventHandler(StudentRegistered, Student.onStudentRegistered);
  }

  static async registerStudent(state, command) {
    const validationErrors = [];

    if (command.studentId === '') validationErrors.push({field: 'studentId', message: 'is required'});
    if (command.name === '') validationErrors.push({field: 'name', message: 'is required'});
    if (command.licenseNo === '') validationErrors.push({field: 'licenseNo', message: 'is required'});
    if (command.phoneNo === '') validationErrors.push({field: 'phoneNo', message: 'is required'});
    if (command.email === '') validationErrors.push({field: 'email', message: 'is required'});
    if (command.testDate === '') validationErrors.push({field: 'testDate', message: 'is required'});
    if (command.testTime === '') validationErrors.push({field: 'testTime', message: 'is required'});
    if (command.testLocation === '') validationErrors.push({field: 'testLocation', message: 'is required'});
    if (command.emergencyContactNo === '') validationErrors.push({field: 'emergencyContactNo', message: 'is required'});
    if (command.emergencyContactName === '') validationErrors.push({field: 'emergencyContactName', message: 'is required'});
    if (command.allergies === '') validationErrors.push({field: 'allergies', message: 'is required'});

    if (validationErrors.length) throw new ValidationFailed(validationErrors);

    if (state.studentId) throw new ValidationFailed('Student already registered');

    return [
      new StudentRegistered(command.studentId, command.name, command.licenseNo, command.phoneNo, command.email, command.testDate, command.testTime, command.testLocation, command.emergencyContactNo, command.emergencyContactName, command.allergies),
    ];
  }

  static onStudentRegistered(state, event) {
    return {...state, studentId: event.studentId};
  }
}
