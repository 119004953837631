import {errorMessageFromPayload} from "../utils/errors";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";
import {
  GET_MANEUVER,
  GET_MANEUVER_FAILED,
  GET_MANEUVER_SUCCEEDED,
  GET_MANEUVERS, GET_MANEUVERS_FAILED,
  GET_MANEUVERS_SUCCEEDED
} from "../actions/maneuvers";

const defaultState = {
  list: [],
  one: null,
  error: '',
  loading: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_MANEUVER: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case GET_MANEUVER_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        one: action.payload,
      };
    }
    case GET_MANEUVER_FAILED: {
      return {
        ...state,
        loading: false,
        error: errorMessageFromPayload(action.payload),
      };
    }
    case GET_MANEUVERS: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case GET_MANEUVERS_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    }
    case GET_MANEUVERS_FAILED: {
      return {
        ...state,
        loading: false,
        error: errorMessageFromPayload(action.payload),
      };
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
