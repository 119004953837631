import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Accordion, Card} from 'react-bootstrap';

class Student extends Component {
  state = {
    toggled: false,
  };

  render() {
    const {
      name, licenseNo, phoneNo, email, testDate, testLocation, testTime, emergencyContactName, emergencyContactNo,
      allergies,
    } = this.props;
    return (
      <Accordion className="my-3">
        <Card>
          <Accordion.Toggle as={Card.Header} variant="link" eventKey={0} onClick={() => this.setState({toggled: !this.state.toggled})}>
            <div style={{float: "right"}}>{this.state.toggled ? '-' : '+'}</div>
            {name}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={0}>
            <Card.Body>
              <Row className="mt-1"><Col xs={6}>License No:</Col><Col xs={6}>{licenseNo}</Col></Row>
              <Row className="mt-1"><Col xs={6}>Phone No:</Col><Col xs={6}>{phoneNo}</Col></Row>
              <Row className="mt-1"><Col xs={6}>Email:</Col><Col xs={6} className="email">{email}</Col></Row>
              <Row className="mt-1"><Col xs={6}>Test Date:</Col><Col xs={6}>{testDate}</Col></Row>
              <Row className="mt-1"><Col xs={6}>Test Time:</Col><Col xs={6}>{testTime}</Col></Row>
              <Row className="mt-1"><Col xs={6}>Test Location:</Col><Col xs={6}>{testLocation}</Col></Row>
              <Row className="mt-1"><Col xs={6}>Emergency Contact Name:</Col><Col
                xs={6}>{emergencyContactName}</Col></Row>
              <Row className="mt-1"><Col xs={6}>Emergency Contact No:</Col><Col xs={6}>{emergencyContactNo}</Col></Row>
              <Row className="mt-1"><Col xs={6}>Allergies:</Col><Col xs={6}>{allergies}</Col></Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

const mapStateToProps = state => state.student;

export default connect(mapStateToProps)(Student);
