import {START_LESSON, START_LESSON_FAILED, START_LESSON_SUCCESS} from '../actions/startLesson';
import {errorMessageFromPayload} from "../utils/errors";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  starting: false,
  error: '',
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case START_LESSON: {
      return {...state, starting: true};
    }
    case START_LESSON_SUCCESS: {
      return {...state, starting: false, error: ''};
    }
    case START_LESSON_FAILED: {
      return {...state, starting: false, error: errorMessageFromPayload(action.payload)};
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
