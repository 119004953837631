export default class InstructorAdded {
  static type = 'InstructorAdded';
  /**
   * @param {!string} instructorId
   * @param {!string} firstName
   * @param {!string} lastName
   * @param {!string} email
   * @param {!string} passwordHash
   */
  constructor(instructorId, firstName, lastName, email, passwordHash) {
    if (typeof instructorId !== 'string') throw new TypeError("instructorId must be a string");
    if (typeof firstName !== 'string') throw new TypeError("firstName must be a string");
    if (typeof lastName !== 'string') throw new TypeError("lastName must be a string");
    if (typeof email !== 'string') throw new TypeError("email must be a string");
    if (typeof passwordHash !== 'string') throw new TypeError("passwordHash must be a string");
    this.instructorId = instructorId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.passwordHash = passwordHash;
  }
}
