import uuid from "uuid";
import {Snapshot, DEFAULT_SNAPSHOT_THRESHOLD} from "./snapshot";
import {CachedAggregate} from "./aggregateCache";
import {delay, merge} from "./utils";

class InvalidAggregateIdError extends Error {
  constructor(message = "Invalid aggregateId, must be a non-empty string.") {
    super();
    Error.captureStackTrace(this, InvalidAggregateIdError);
    this.name = InvalidAggregateIdError.name;
    this.message = message;
  }
}

const DEFAULT_RETRY_ATTEMPTS = 5;
const DEFAULT_BACK_OFF_DELAYS_MS = [100, 100, 200, 300, 500];

/**
 * @param {object} config
 * @param {eventFactory} eventFactory
 * @param {EventStore} eventStore
 * @param {AggregateCache} aggregateCache
 * @param {SnapshotStore} snapshotStore
 * @param {Logger} logger
 * @return {commandHandler}
 */
export default function factory(config, eventFactory, eventStore, aggregateCache, snapshotStore, logger) {
  const snapshotThreshold = config.snapshotThreshold || DEFAULT_SNAPSHOT_THRESHOLD;

  async function loadAggregateAndEvents(TAggregate, aggregateId) {
    const streamName = `${TAggregate.name}-${aggregateId}`;

    const cached = await aggregateCache.get(streamName);
    if (cached) {
      const aggregate = cached.aggregate;
      const events = await eventStore.read(streamName, cached.streamRevision + 1);
      const expectedVersion = cached.streamRevision;
      const lastSnapshotVersion = cached.lastSnapshotRevision;
      return {
        aggregate,
        expectedVersion,
        lastSnapshotVersion,
        events
      };
    }

    const aggregate = new TAggregate();
    const snapshot = await snapshotStore.get(streamName);
    if (snapshot) {
      aggregate.restoreFromMemento(snapshot.memento);
      const events = await eventStore.read(streamName, snapshot.streamRevision + 1);
      const expectedVersion = cached.streamRevision;
      const lastSnapshotVersion = snapshot.streamRevision;
      return {
        aggregate,
        expectedVersion,
        lastSnapshotVersion,
        events
      };
    }

    const events = await eventStore.read(streamName);
    const expectedVersion = -1;
    const lastSnapshotVersion = -1;
    return {
      aggregate,
      expectedVersion,
      lastSnapshotVersion,
      events
    };
  }

  async function saveAggregateAndEvents(aggregate, aggregateId, expectedVersion, lastSnapshotVersion, uncommittedEvents, metadata) {
    const streamName = `${aggregate.constructor.name}-${aggregateId}`;
    const currentVersion = await eventStore.save(streamName, uncommittedEvents, expectedVersion, metadata);
    try {
      for (const event of uncommittedEvents) {
        aggregate.hydrate(event);
      }
      if ((currentVersion - lastSnapshotVersion) >= snapshotThreshold) {
        await snapshotStore.add(new Snapshot(streamName, currentVersion, aggregate.createMemento()));
        lastSnapshotVersion = currentVersion;
      }
      await aggregateCache.set(new CachedAggregate(streamName, currentVersion, lastSnapshotVersion, aggregate));
    } catch (e) {
      logger.warn(e.stack);
    }
  }

  async function commandHandler(TAggregate, aggregateId, command, metadata = {}) {
    if (typeof TAggregate !== 'function') throw new TypeError("TAggregate must be a function.");
    if (typeof command !== 'object' || command === null) throw new TypeError("command must be a non-null object.");
    if (typeof aggregateId !== 'string' || aggregateId === "") throw new InvalidAggregateIdError();

    const defaultMetadata = {
      timestamp: Date.now(),
      $correlationId: uuid.v4()
    };
    const mergedMetadata = merge(defaultMetadata, metadata);

    for (let retryAttempt = 0; retryAttempt < DEFAULT_RETRY_ATTEMPTS; retryAttempt++) {
      try {
        // load
        const loadResult = await loadAggregateAndEvents(TAggregate, aggregateId);
        const {aggregate, lastSnapshotVersion, events} = loadResult;
        let {expectedVersion} = loadResult;
        // hydrate
        for (const esData of events) {
          const ev = eventFactory(esData.eventType, esData.data);
          aggregate.hydrate(ev);
          expectedVersion++;
        }
        // execute
        const uncommittedEvents = await aggregate.execute(command);
        // save
        await saveAggregateAndEvents(aggregate, aggregateId, expectedVersion, lastSnapshotVersion, uncommittedEvents, mergedMetadata);
        break;
      } catch (err) {
        if (err.name !== 'WrongExpectedVersionError') throw err;
        await delay(DEFAULT_BACK_OFF_DELAYS_MS[retryAttempt]);
      }
    }

    return command;
  }

  return commandHandler;
}

/**
 * @callback commandHandler
 * @async
 * @param {Function} TAggregate
 * @param {string} aggregateId
 * @param {object} command
 * @param {object} [metadata]
 * @returns Promise<object>
 */
