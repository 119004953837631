import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Form} from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import {addSkillOption} from '../actions/addSkillOption';
import {getGlobalSkills} from '../actions/globalSkills';

class AddSkillOptionPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    // state
    adding: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    globalSkills: PropTypes.array.isRequired,
    // actions
    getGlobalSkills: PropTypes.func.isRequired,
    addSkillOption: PropTypes.func.isRequired,
  };

  state = {
    name: '',
    globalSkill: [],
  };

  componentDidMount() {
    this.props.getGlobalSkills({order: 'createdAt ASC'});
  }

  setFormValue = (evt) => this.setState({[evt.target.name]: evt.target.value});

  onSubmit = async(evt) => {
    evt.preventDefault();

    const {globalSkill, ...data} = this.state;
    if (globalSkill.length < 1) return;
    const globalSkillId = globalSkill[0].id;
    const {addSkillOption, history} = this.props;
    try {
      await addSkillOption({...data, globalSkillId});
      history.goBack();
    } catch (err) {
      //nothing
    }
  };

  onGlobalSkillChange = (selected) => {
    this.setState({globalSkill: selected});
  };

  render() {
    const {globalSkills, adding, error} = this.props;
    const {globalSkill, name} = this.state;

    const options = globalSkills.map(x => ({id: x.globalSkillId, label: x.name}));
    const disableSubmit = adding || globalSkill.length < 1 || name === '';

    return (
      <div id="AddSkillOptionPage">
        <h3>Add Comment</h3>
        <div className="error">{error}</div>
        <div>
          <Form onSubmit={this.onSubmit}>
            <Form.Group>
              <Form.Label>Global Skill:</Form.Label>
              <Typeahead id="globalSkillSelector"
                         placeholder="Select a global skill"
                         autoFocus
                         selected={globalSkill}
                         options={options}
                         onChange={this.onGlobalSkillChange}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control type="text" name="name" value={name} onChange={this.setFormValue}/>
            </Form.Group>
            <Form.Group>
              <input disabled={disableSubmit} type="submit" value="Add"/>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.addSkillOption,
  globalSkills: state.globalSkills.list,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  getGlobalSkills,
  addSkillOption
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddSkillOptionPage);
