import { LOGIN_USER, LOGIN_USER_FAILED, LOGIN_USER_SUCCESS, LOGOUT_USER } from '../actions/auth';
import { INIT_STATE } from '../actions/init';
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

class Auth {
  constructor(token) {
    this._useToken(token);
  }
  _useToken(token) {
    console.debug('Using token', token);
    if (token && token.token_type === 'local') {
      this._refresh_token = '';
      this._access_token = '';
      this._roles = token.access_token.roles;
      this._userId = token.access_token.userId;
      this._expires = Date.now() + (60*60*1000);
      return;
    }

    const tok = Auth._parseAccessToken(token && token.access_token);
    if (typeof tok !== 'object' || tok === null) {
      this._refresh_token = '';
      this._access_token = '';
      this._roles = [];
      this._userId = '';
      this._expires = 0;
      return;
    }
    this._refresh_token = token.refresh_token;
    this._access_token = token.access_token;
    this._roles = tok.roles;
    this._userId = tok.sub;
    this._expires = tok.exp * 1000;
  }
  isLoggedIn() {
    return !!this._userId;
  }
  isExpired() {
    return Date.now() >= this._expires;
  }
  hasRole(role) {
    return this._roles.includes(role);
  }
  get userId() {
    return this._userId;
  }
  get accessToken() {
    return this._access_token;
  }
  get refreshToken() {
    return this._refresh_token;
  }

  static _parseAccessToken(access_token) {
    if (typeof access_token !== 'string' || access_token === '') return null;
    try {
      const [, payload,] = access_token.split('.');
      const json = atob(payload);
      return JSON.parse(json);
    } catch (err) {
      return null;
    }
  }
}

const defaultState = {
  loginError: '',
  token: null,
  loginAs: '',
  username: '',
  auth: new Auth()
};

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case INIT_STATE: {
      return {
        ...state,
        ...action.payload.auth,
        auth: new Auth(action.payload.auth.token)
      }
    }
    case LOGIN_USER: {
      const {username} = action.payload;
      return { ...state, loginAs: username }
    }
    case LOGIN_USER_SUCCESS: {
      const { loginAs } = state;
      return { ...state, token: action.payload, auth: new Auth(action.payload), loginError: '', username: loginAs }
    }
    case LOGIN_USER_FAILED: {
      return { ...state, loginError: errorMessage(action.payload) }
    }
    case LOGOUT_USER: {
      return defaultState
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state
  }
}

function errorMessage (err) {
  if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message
  }
  return err.message
}
