import React, {Component} from 'react'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types'
import {completeLesson} from "../actions/completeLesson";
import {Form, Button} from "react-bootstrap";
import {setPageTitle} from "../actions/nav";
import "./CompleteLessonPage.css"
import Student from "./Student";

const defaultState = {
    signature: '',
};

export class CompleteLessonPage extends Component {

    static propTypes = {
        // action
        completeLesson: PropTypes.func.isRequired,
        // state
        error: PropTypes.string.isRequired,
        lessonId: PropTypes.string.isRequired,
        history: PropTypes.object.isRequired,
    };

    componentDidMount() {
        this.props.setPageTitle('Assessment');
    }

    state = Object.assign({}, defaultState);

    setFormValue = (evt) => this.setState({[evt.target.name]: evt.target.value});

    render() {
        return (
          <div id="CompleteLessonPage">
            <Student />
            <Form onSubmit={this.onSubmit}>
                <Form.Group className="error">
                    {this.props.error}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Signature</Form.Label>
                    <Form.Control autoFocus type="text" name="signature" value={this.state.signature} onChange={this.setFormValue} />
                </Form.Group>
                <Form.Group>
                    <Button type="submit">End Lesson</Button>
                </Form.Group>
            </Form>
          </div>
        )
    }

    onSubmit = async (evt) => {
        evt.preventDefault();
        const {signature} = this.state;
        const {lessonId} = this.props;
        try {
            await this.props.completeLesson({lessonId, signature, timestamp: new Date().toISOString()});
            this.props.history.push('/');
        } catch (err) {
            // nothing to do, error message is in error prop
        }
    }
}

const mapStateToProps = state => ({
    ...state.completeLesson,
    lessonId: state.assessment.lessonId,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setPageTitle,
    completeLesson
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompleteLessonPage);
