import {GET_LESSONS, GET_LESSONS_FAILED, GET_LESSONS_SUCCESS} from "../actions/lessons";
import {errorMessageFromPayload} from "../utils/errors";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  list: [],
  loading: false,
  error: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_LESSONS: {
      return {...state, loading: true, error: ''};
    }
    case GET_LESSONS_SUCCESS: {
      return {...state, loading: false, list: action.payload};
    }
    case GET_LESSONS_FAILED: {
      return {...state, loading: false, error: errorMessageFromPayload(action.payload)};
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
