import {ADD_MANEUVER, ADD_MANEUVER_FAILED, MANEUVER_ADDED} from "../actions/addManeuver";
import {errorMessageFromPayload} from "../utils/errors";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  adding: false,
  error: '',
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_MANEUVER: {
      return {
        ...state,
        adding: true,
        error: '',
      };
    }
    case MANEUVER_ADDED: {
      return {
        ...state,
        adding: false,
      };
    }
    case ADD_MANEUVER_FAILED: {
      return {
        ...state,
        adding: false,
        error: errorMessageFromPayload(action.payload),
      };
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
