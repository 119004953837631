import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {addManeuver} from "../actions/addManeuver";
import {Form} from "react-bootstrap";

class AddManeuverPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    // state
    adding: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    // actions
    addManeuver: PropTypes.func.isRequired,
  };

  state = {
    name: '',
  };

  setFormValue = (evt) => this.setState({[evt.target.name]: evt.target.value});

  onSubmit = async(evt) => {
    evt.preventDefault();

    const {addManeuver, history} = this.props;
    const {...data} = this.state;
    try {
      await addManeuver(data);
      history.goBack();
    } catch (err) {
      //nothing
    }
  };

  render() {
    return (
      <div id="AddManeuverPage">
        <h3>Add ICBC Maneuver</h3>
        <div className="error">{this.props.error}</div>
        <div>
          <Form onSubmit={this.onSubmit}>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control type="text" name="name" value={this.state.name} onChange={this.setFormValue}/>
            </Form.Group>
            <Form.Group>
              <input disabled={this.props.adding} type="submit" value="Add"/>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.addManeuver
});
const mapDispatchToProps = dispatch => bindActionCreators({
  addManeuver
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddManeuverPage);
