import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button } from 'react-bootstrap'
import { getInstructors } from '../actions/instructors'

export class InstructorsPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    // state
    list: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    // action
    getInstructors: PropTypes.func.isRequired,
  };

  componentDidMount () {
    this.loadInstructors()
  }

  loadInstructors = async() => {
    try {
      await this.props.getInstructors()
    } catch (err) {
      // nothing to do, error message is in error prop
    }
  };

  addClick = () => {
    this.props.history.push('/instructor/add')
  };

  render() {
    const {list} = this.props;
    return <div>
      <h3>Instructors</h3>
      <Button onClick={this.addClick}>Add</Button>
      <table>
        <thead>
          <tr><th>Name</th></tr>
        </thead>
        <tbody>
        {list.map(({instructorId, name}) =>
          <tr key={instructorId}><td>{name}</td></tr>
        )}
        </tbody>
      </table>
    </div>
  }
}

const mapStateToProps = state => ({
  ...state.instructors
});
const mapDispatchToProps = dispatch => bindActionCreators({
  getInstructors
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InstructorsPage);
