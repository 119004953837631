export const config = {
  key: 'assessmentId',
  schema: {
    assessmentId: {type: 'string', format: 'uuid', nullable: false},
    lessonId: {type: 'string', format: 'uuid', nullable: false},
    maneuverId: {type: 'string', format: 'uuid', nullable: false},
    globalSkillId: {type: 'string', format: 'uuid', nullable: false},
    globalSkillName: {type: 'string', nullable: false},
    skillOptionId: {type: 'string', format: 'uuid', nullable: false},
    maneuverTypeId: {type: 'string', format: 'uuid', nullable: false},
    timestamp: {type: 'string', nullable: false},
  }
};

export const lookups = {
  globalSkills: {
    key: 'globalSkillId',
    schema: {
      globalSkillId: {type: 'string', format: 'uuid', nullable: false},
      name: {type: 'string', nullable: false}
    }
  }
};

export async function handler(assessments, eventData, {globalSkills, scores}) {
  const {typeId, event} = eventData;
  switch (typeId) {
    case 'GlobalSkillAdded': {
      const {globalSkillId, name} = event;
      globalSkills.create({globalSkillId, name});
      break;
    }
    case 'SkillAssessed': {
      const {assessmentId, lessonId, maneuverId, globalSkillId, skillOptionId, maneuverTypeId, timestamp} = event;
      const {name: globalSkillName} = await globalSkills.findOne({globalSkillId});
      assessments.create({
        assessmentId,
        lessonId,
        maneuverId,
        globalSkillId,
        globalSkillName,
        skillOptionId,
        maneuverTypeId,
        timestamp
      });
      break;
    }
    default:
  }
  return assessments;
}
