import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {addManeuverType} from "../actions/addManeuverType";
import {getManeuvers} from "../actions/maneuvers";
import {Form} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";

class AddManeuverTypePage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    // state
    adding: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    // actions
    addManeuverType: PropTypes.func.isRequired,
    getManeuvers: PropTypes.func.isRequired,
  };

  state = {
    maneuverIds: [],
    name: ''
  };

  componentDidMount() {
    try {
      this.props.getManeuvers();
    } catch (e) {
      //
    }
  }

  setFormValue = (evt) => this.setState({[evt.target.name]: evt.target.value});

  onSubmit = async(evt) => {
    evt.preventDefault();

    const {addManeuverType, history} = this.props;
    const {...data} = this.state;
    try {
      await addManeuverType(data);
      history.goBack();
    } catch (err) {
      //nothing
    }
  };

  onManeuversChange = (selected) => this.setState({maneuverIds: selected.map(({id}) => id)});

  render() {
    return (
      <div id="AddManeuverTypePage">
        <h3>Add Maneuver Type / Traffic Option</h3>
        <div className="error">{this.props.error}</div>
        <div>
          <Form onSubmit={this.onSubmit}>
            <Form.Group>
              <Form.Label>Maneuvers:</Form.Label>
              <Typeahead id="maneuverIds"
                         placeholder="Select maneuver(s)"
                         autoFocus
                         multiple
                         options={this.props.maneuvers.map(({maneuverId, name}) => ({id: maneuverId, label: name}))}
                         onChange={this.onManeuversChange}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control type="text" name="name" value={this.state.name} onChange={this.setFormValue}/>
            </Form.Group>
            <Form.Group>
              <input disabled={this.props.adding} type="submit" value="Add"/>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.addManeuverType
});
const mapDispatchToProps = dispatch => bindActionCreators({
  addManeuverType,
  getManeuvers,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddManeuverTypePage);
