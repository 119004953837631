import serviceRegistryFactory from "./serviceRegistry";
import initRead from "./initRead";
import initWrite from "./initWrite";

/**
 * @param {object} options
 * @param {object} options.config
 * @param {Logger} options.logger
 * @param {function} options.esBootstrap
 * @param {function} options.storageBootstrap
 * @param {object[]} options.readModels
 * @param {eventFactory} options.eventFactory
 * @param {function[]} options.controllersFactories
 * @param {function} [options.servicesBootstrap]
 * @param {object} [options.services]
 * @return {Promise<object>} services
 */
export default async function wireUp(options) {
  const {
    config, logger, esBootstrap, storageBootstrap, readModels, eventFactory, controllersFactories, servicesBootstrap,
    initWeb, services
  } = options;

  const _services = serviceRegistryFactory();
  if (services) {
    for (const k in services) {
      _services[k] = services[k];
    }
  }
  _services.config = config;
  _services.logger = logger;

  await esBootstrap(_services);
  await initRead(_services, storageBootstrap, fromMap(readModels));
  await initWrite(_services, eventFactory);
  if (servicesBootstrap) {
    await servicesBootstrap(_services);
  }
  if (initWeb) {
    await initWeb(_services, controllersFactories);
  }

  return _services;
}

function fromMap(m) {
  return Object.keys(m).map(k => ({
    name: k,
    ...m[k]
  }));
}
