import {LESSON_COMPLETE, LESSON_COMPLETE_SUCCESS, LESSON_COMPLETE_FAILED} from '../actions/completeLesson'
import {errorMessageFromPayload} from "../utils/errors";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";


const defaultState = {
    starting: false,
    error: '',
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case LESSON_COMPLETE: {
            return {...state, starting: true}
        }
        case LESSON_COMPLETE_SUCCESS: {
            return {...state, starting: false, error: ''}
        }
        case LESSON_COMPLETE_FAILED: {
            return {...state, starting: false, error: errorMessageFromPayload(action.payload)}
        }
        case SYNC_RESET_DEVICE_SUCCESS: {
            return defaultState;
        }
        default:
            return state;
    }
}
