import {localFind, localFindOne} from "../utils/api";

export const GET_SKILL_OPTION = 'GET_SKILL_OPTION';
export const GET_SKILL_OPTION_SUCCESS = 'GET_SKILL_OPTION_SUCCESS';
export const GET_SKILL_OPTION_FAILED = 'GET_SKILL_OPTION_FAILED';
export const getGlobalSkill = localFindOne('v1/skillOption/findOne', GET_SKILL_OPTION, GET_SKILL_OPTION_SUCCESS,
  GET_SKILL_OPTION_FAILED);

export const GET_SKILL_OPTIONS = 'GET_SKILL_OPTIONS';
export const GET_SKILL_OPTIONS_SUCCESS = 'GET_SKILL_OPTIONS_SUCCESS';
export const GET_SKILL_OPTIONS_FAILED = 'GET_SKILL_OPTIONS_FAILED';
export const getSkillOptions = localFind('v1/skillOption/find', GET_SKILL_OPTIONS, GET_SKILL_OPTIONS_SUCCESS, GET_SKILL_OPTIONS_FAILED);
