import {
  GET_GLOBAL_SKILL,
  GET_GLOBAL_SKILL_FAILED,
  GET_GLOBAL_SKILL_SUCCESS,
  GET_GLOBAL_SKILLS,
  GET_GLOBAL_SKILLS_FAILED,
  GET_GLOBAL_SKILLS_SUCCESS
} from "../actions/globalSkills";
import {errorMessageFromPayload} from "../utils/errors";
import {toHash} from "../utils/transform";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  list: [],
  one: null,
  error: '',
  loading: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_GLOBAL_SKILL: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case GET_GLOBAL_SKILL_SUCCESS: {
      return {
        ...state,
        loading: false,
        one: action.payload,
      };
    }
    case GET_GLOBAL_SKILL_FAILED: {
      return {
        ...state,
        loading: false,
        error: errorMessageFromPayload(action.payload),
      };
    }
    case GET_GLOBAL_SKILLS: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case GET_GLOBAL_SKILLS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: action.payload,
        map: toHash(action.payload, 'globalSkillId'),
      };
    }
    case GET_GLOBAL_SKILLS_FAILED: {
      return {
        ...state,
        loading: false,
        error: errorMessageFromPayload(action.payload),
      };
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
