import { hashPassword } from '../utils/passwordHashing'

export default class AuthController {
  constructor({buildAppRouter, readRepository, findUser, onAuthSuccess}) {
    this._router = buildAppRouter('auth', 1);
    this._router.command('token', async({body}) => {
      const {grant_type, username, password} = body;

      if (grant_type !== 'password') {
        return this._router.badRequest({message: 'invalid grant_type'});
      }
      const user = await findUser({username});
      if (!user) {
        return this._router.unauthorized();
      }
      if ((await hashPassword(password, user.passwordHash)) !== user.passwordHash) {
        return this._router.unauthorized();
      }

      //We can't produce a bearer token, because that would mean exposing the server's secret
      //We can only produce a valid "local" token
      return {access_token: user, token_type: 'local'};
    });
  }
}
