import axios from "axios";
import auth from "./auth";
import API from "../cqrs/index.js";

function authorizationHeaders() {
  const authInstance = auth();
  if (!authInstance.isLoggedIn()) return {};
  const headers = {
    'X-UserId': authInstance.userId()
  };
  const access_token = authInstance.access_token();
  if (access_token) {
    headers['Authorization'] = "Bearer " + access_token;
  }
  return headers;
}

export function remoteCommandWithLocalFallback(uri, actionType, successType, failureType, {extraData} = {}) {
  return function(payload) {
    return async function(dispatch) {
      dispatch({
        type: actionType,
        payload: payload,
      });
      let action;
      try {
        let res;
        try {
          const url = `${process.env.PUBLIC_URL}/api/${uri}`;
          res = await axios.post(url, { ...extraData, ...payload }, { headers: authorizationHeaders() });
        } catch (err) {
          console.debug('remote command failed, will try on local', err, err.response, err.request);
        }
        if (!res) {
          const api = await API();
          res = await api.command(uri, {...extraData, ...payload}, { headers: authorizationHeaders()});
        }
        action = {
          type: successType,
          payload: res.data
        };
      } catch (err) {
        action = {
          type: failureType,
          payload: err
        };
        throw err;
      } finally {
        dispatch(action);
      }
    };
  };
}

export function remoteCommand(uri, actionType, successType, failureType, {extraData} = {}) {
  return function(payload) {
    return async function(dispatch) {
      dispatch({
        type: actionType,
        payload: payload,
      });
      let action;
      try {
        const url = `${process.env.PUBLIC_URL}/api/${uri}`;
        const res = await axios.post(url, {...extraData, ...payload}, {headers: authorizationHeaders()});
        action = {
          type: successType,
          payload: res.data
        };
      } catch (err) {
        action = {
          type: failureType,
          payload: err
        };
        throw err;
      } finally {
        dispatch(action);
      }
    };
  };
}

export function localCommand(uri, actionType, successType, failureType, {extraData} = {}) {
  return function(payload) {
    return async function(dispatch) {
      dispatch({
        type: actionType,
        payload: payload,
      });
      let action;
      try {
        const api = await API();
        const res = await api.command(uri, {...extraData, ...payload}, {headers: authorizationHeaders()});
        action = {
          type: successType,
          payload: res.data
        };
      } catch (err) {
        action = {
          type: failureType,
          payload: err
        };
        throw err;
      } finally {
        dispatch(action);
      }
    };
  };
}

export function localFind(uri, actionType, successType, failureType) {
  return function(payload) {
    return async function(dispatch) {
      dispatch({
        type: actionType,
        payload: payload,
      });
      let action;
      try {
        const api = await API();
        const res = await api.find(uri, payload, {headers: authorizationHeaders()});
        //const res = await axios.get(url, {params: payload, headers: authorizationHeaders()});
        action = {
          type: successType,
          payload: res.data
        };
      } catch (err) {
        action = {
          type: failureType,
          payload: err
        };
        throw err;
      } finally {
        dispatch(action);
      }
    };
  };
}

export function remoteFind(uri, actionType, successType, failureType) {
  return function(payload) {
    return async function(dispatch) {
      dispatch({
        type: actionType,
        payload: payload,
      });
      let action;
      try {
        //const api = await API()
        //const res = await api.find(uri, payload, {headers: authorizationHeaders()});
        const res = await axios.get(`${process.env.PUBLIC_URL}/api/${uri}`, {params: payload, headers: authorizationHeaders()});
        action = {
          type: successType,
          payload: res.data
        };
      } catch (err) {
        action = {
          type: failureType,
          payload: err
        };
        throw err;
      } finally {
        dispatch(action);
      }
    };
  };
}


export function localFindOne(uri, actionType, successType, failureType) {
  return function(payload) {
    return async function(dispatch) {
      dispatch({
        type: actionType,
        payload: payload,
      });
      let action;
      try {
        const api = await API();
        const res = await api.findOne(uri, payload, {headers: authorizationHeaders()});
        //const res = await axios.get(url, {params: {...payload, offset: 0, limit: 1}, headers: authorizationHeaders()});
        action = {
          type: successType,
          payload: res.data,
        };
      } catch (err) {
        action = {
          type: failureType,
          payload: err
        };
        throw err;
      } finally {
        dispatch(action);
      }
    };
  };
}
