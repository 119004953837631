import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Accordion, Button, Col, Container, Dropdown, DropdownButton, Row} from 'react-bootstrap';
import {bindActionCreators} from "redux";
import moment from 'moment';
import {setPageTitle} from "../actions/nav";
import {getLessons} from "../actions/lessons";
import {getAssessments} from "../actions/assessment";
import {getGlobalSkills} from "../actions/globalSkills";
import {getManeuvers} from "../actions/maneuvers";
import {getManeuverTypes} from "../actions/maneuverTypes";
import {getSkillOptions} from "../actions/skillOptions";

const styles = {
  noPadding: {
    padding: 0
  },
  topSpacing: {
    marginTop: '1rem'
  }
};

const filters = ['All Lessons', 'Last 6 Lessons', 'First 6 Lessons'];

class LessonSummaryPage extends Component {
  static propTypes = {
    studentId: PropTypes.string.isRequired,
    lessons: PropTypes.array.isRequired,
    assessments: PropTypes.array.isRequired,
    maneuvers: PropTypes.array.isRequired,
    maneuverTypes: PropTypes.array.isRequired,
    globalSkills: PropTypes.array.isRequired,
    skillOptions: PropTypes.array.isRequired,
    student: PropTypes.object.isRequired,
    //
    setPageTitle: PropTypes.func.isRequired,
    getLessons: PropTypes.func.isRequired,
    getAssessments: PropTypes.func.isRequired,
    getGlobalSkills: PropTypes.func.isRequired,
    getManeuvers: PropTypes.func.isRequired,
    getManeuverTypes: PropTypes.func.isRequired,
    getSkillOptions: PropTypes.func.isRequired,
  };

  state = {
    filter: 0,
    lessons: [],
    data: [],
    maneuvers: [],
    maneuverTypes: [],
    globalSkills: [],
  };

  async componentDidMount() {
    const {studentId} = this.props;
    this.props.setPageTitle('Assessment');
    await Promise.all([
      this.props.getLessons({where: {studentId}, order: 'lessonDateTime DESC'}),
      this.props.getGlobalSkills({order: 'createdAt ASC'}),
      this.props.getManeuvers(),
      this.props.getManeuverTypes(),
      this.props.getSkillOptions(),
    ]);
    await this.props.getAssessments({where: {lessonId: {inq: this.props.lessons.map(({lessonId}) => lessonId)}}});
    this.report(0);
  }

  report(filter) {
    const {lessons, assessments, globalSkills, maneuvers, maneuverTypes, skillOptions} = this.props;

    let lessonsToDisplay = [], filteredAssessments;
    switch (filter) {
      case 0:
        filteredAssessments = assessments;
        if (lessons.length > 6) break;
        lessonsToDisplay = lessons;
        break;
      case 1:
        lessonsToDisplay = lessons.slice(0, 6);
        filteredAssessments = assessments.filter(x => !!lessonsToDisplay.find(y => y.lessonId === x.lessonId));
        break;
      case 2:
        const start = lessons.length - 7;
        lessonsToDisplay = lessons.slice(start >= 0 ? start : 0);
        filteredAssessments = assessments.filter(x => !!lessonsToDisplay.find(y => y.lessonId === x.lessonId));
        break;
      default:
        throw new Error("unreachable");
    }
    const lessonsData = lessonsToDisplay.map(({lessonDateTime}) => moment(lessonDateTime).format('MMMM D, Y'));

    const globalSkillsData = [];
    const globalSkillById = {};
    for(const gs of globalSkills) {
      const row = {
        id: gs.globalSkillId, name: gs.name, isError: gs.isError, count: 0, total: 0, percent: 0, assessments: [],
        score: gs.isIndependent ? 1 : 0,
      };
      globalSkillById[row.id] = row;
      if (!gs.isError) continue;
      globalSkillsData.push(row);
    }

    const maneuversData = [];
    const maneuverById = {};
    for (const maneuver of maneuvers) {
      const row = {
        id: maneuver.maneuverId,
        name: maneuver.name,
        score: 0,
        observations: 0,
        designation: "more data needed",
        assessments: [],
      };
      maneuverById[maneuver.maneuverId] = row;
      maneuversData.push(row);
    }

    const maneuverTypesData = [];
    const maneuverTypeById = {};
    for (const maneuverType of maneuverTypes) {
      let row;
      if (maneuverType.maneuverIds.length > 1) {
        row = {id: maneuverType.maneuverTypeId, name: maneuverType.name, count: 0, percentage: 0};
        maneuverTypesData.push(row);
      } else {
        let maneuverId = maneuverType.maneuverIds[0];
        row = maneuverTypesData.find(x => x.id === maneuverId);
        if (!row) {
          row = {id: maneuverId, name: maneuverById[maneuverId].name, count: 0, percentage: 0};
          maneuverTypesData.push(row);
        }
      }
      maneuverTypeById[maneuverType.maneuverTypeId] = row;
    }

    const skillOptionById = {};
    for (const skillOption of skillOptions) {
      skillOptionById[skillOption.skillOptionId] = skillOption;
    }

    for(const assessment of filteredAssessments) {
      const gs = globalSkillById[assessment.globalSkillId];
      if (gs.isError) {
        gs.count++;
        for (const gs of globalSkillsData) {
          gs.total++;
          gs.percent = gs.count / gs.total * 100;
        }
      }

      const maneuverType = maneuverTypeById[assessment.maneuverTypeId];
      maneuverType.count++;
      maneuverType.percentage = maneuverType.count / filteredAssessments.length * 100;

      const maneuver = maneuverById[assessment.maneuverId];
      const readableAssessment = {
        ...assessment,
        maneuverName: maneuver.name,
        globalSkillName: gs.name,
        score: gs.score,
        notes: skillOptionById[assessment.skillOptionId].name,
      };
      gs && gs.assessments.push(readableAssessment);

      maneuver.assessments.push(readableAssessment);
      maneuver.observations++;
      maneuver.score += readableAssessment.score;
      if (maneuver.observations < 5) {
        continue;
      }
      const score = maneuver.score / maneuver.observations;
      if (score >= 0.9) maneuver.designation = 'independent';
      else if (score >= 0.7) maneuver.designation = 'near independent';
      else if (score >= 0.5) maneuver.designation = 'mostly dependent';
      else maneuver.designation = 'needs theory/practice';
    }

    this.setState({
      filter,
      nbAssessments: filteredAssessments.length,
      lessons: lessonsData,
      maneuvers: maneuversData,
      globalSkills: globalSkillsData,
      maneuverTypes: maneuverTypesData,
    }, () => this.btnRef.focus());
  }

  handleKeyUp = (evt) => {
    const {history} = this.props;
    switch(evt.which) {
      case 37: //left
        history.goBack();
        break;
      default:
        return false;
    }
  };

  handleEndLessonClick = () => {
    const {history} = this.props;
    history.goBack();
  };

  onFilterSelect = (eventKey) => this.report(parseInt(eventKey));

  render() {
    const {nbAssessments, maneuvers, globalSkills, maneuverTypes, filter, lessons} = this.state;
    return <div id="LessonsSummaryPage">
      <DropdownButton id="lessons-summary-dropdown" title={filters[filter]} className="my-3">
        {filters.map((item, index) =>
        <Dropdown.Item key={index} active={index === filter} eventKey={index} onSelect={this.onFilterSelect}>{item}</Dropdown.Item>
        )}
      </DropdownButton>
      <Row>
        {lessons.map((item, index) =>
        <Col key={index} xs={12}>Lesson of {item}</Col>
        )}
      </Row>
      <Container className="my-3">
        <Row>
          <Col xs={2} className="pl-0">{nbAssessments}</Col>
          <Col xs={10} className="pr-0">observation{nbAssessments > 1 ? 's' : ''}</Col>
        </Row>
        {maneuverTypes.map(item =>
        <Row key={item.id}>
          <Col xs={2} className="pl-0">{item.percentage.toFixed(0)} %</Col>
          <Col xs={10} className="pr-0">{item.name}</Col>
        </Row>
        )}
      </Container>
      <Container className="my-3">
        {maneuvers.map(item =>
        <Accordion key={item.id}>
          <Accordion.Toggle as={Row} eventKey={item.id}>
            <Col xs={4} className="pl-0">{item.name}</Col>
            <Col xs={2}>{item.score}/{item.observations}</Col>
            <Col xs={6} className="pr-0">{item.designation}</Col>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={item.id}>
            <Container className="p-0">
              {item.assessments.map(ass =>
              <Row key={ass.assessmentId}>
                <Col xs={5}>{ass.globalSkillName}</Col>
                <Col xs={2}>{ass.score}</Col>
                <Col xs={5}>{ass.notes}</Col>
              </Row>
              )}
            </Container>
          </Accordion.Collapse>
        </Accordion>
        )}
      </Container>
      <Container className="my-3">
        {globalSkills.map(gs =>
        <Accordion key={gs.id}>
          <Accordion.Toggle as={Row} eventKey={gs.id}>
            <Col xs={9} className="pl-0">{gs.name}</Col>
            <Col xs={3} className="pr-0">{gs.percent.toFixed(0)} %</Col>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={gs.id}>
            <Container className="p-0">
            {gs.assessments.map(ass =>
              <Row key={ass.assessmentId}>
                <Col xs={6}>{ass.maneuverName}</Col>
                <Col xs={6}>{ass.notes}</Col>
              </Row>
            )}
            </Container>
          </Accordion.Collapse>
        </Accordion>
        )}
      </Container>
      <Row style={styles.topSpacing}>
        <Col xs={12} style={{width: '100%', textAlign: 'center'}}>
          <Button className="mx-2" onClick={this.handleEmailTextReport}>Email Text Report</Button>
          <Button className="mx-2 px-4" ref={ref => this.btnRef = ref}
                  onClick={this.handleEndLessonClick}
                  onKeyUp={this.handleKeyUp}>
            Back
          </Button>
        </Col>
      </Row>
    </div>;
  }

  handleEmailTextReport = () => {
    const student = this.props.student;
    const subject = `${student.name} report`;
    const body = `Name: ${student.name}\nLicense #: ${student.licenseNo}\n\nLessons:\n- ` +
      this.props.lessons.map(lesson => moment(lesson.lessonDateTime).format('MMMM Do YYYY, hh:mm a')).join("\n- ");
    window.open(`mailto:report@icbc.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
  };
}

const mapStateToProps = state => ({
  studentId: state.student.studentId,
  assessments: state.assessments.list,
  lessons: state.lessons.list,
  globalSkills: state.globalSkills.list,
  maneuvers: state.maneuvers.list,
  maneuverTypes: state.maneuverTypes.list,
  skillOptions: state.skillOptions.list,
  student: state.student,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  setPageTitle,
  getLessons,
  getAssessments,
  getGlobalSkills,
  getManeuvers,
  getManeuverTypes,
  getSkillOptions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LessonSummaryPage);
