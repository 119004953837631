export default class Subscription {
  /**
   * @param {EventStore} eventStore
   * @param {Logger} logger
   * @param {Position} fromPosition
   * @param {Function<EventData>} eventAppeared
   * @param {Function<void>} liveProcessingStarted
   * @param {Function<EventStore,string,Error>} subscriptionDropped
   */
  constructor (eventStore, logger, fromPosition, eventAppeared, liveProcessingStarted, subscriptionDropped) {
    this._logger = logger;
    this._eventStore = eventStore;
    this._fromPosition = fromPosition;
    this._eventAppeared = eventAppeared;
    this._liveProcessingStarted = liveProcessingStarted;
    this._subscriptionDropped = subscriptionDropped;
  }

  start() {
    if (this._started) {
      throw new Error("Subscription already started");
    }
    this._started = true;
    setTimeout(() => this._catchUp(), 0);
  }

  async _catchUp() {
    try {
      let position = this._fromPosition;
      let done = false;
      do {
        const {isEndOfStream, nextPosition, events} = await this._eventStore.readAllBatch(position);
        done = isEndOfStream;
        position = nextPosition;
        for(const eventData of events) {
          await this._onEventAppeared(eventData);
        }
      } while(!done);
      this._handler = this._onEventAppeared.bind(this);
      this._eventStore.on('eventAppeared', this._handler);
      if (this._liveProcessingStarted) this._liveProcessingStarted();
    } catch (err) {
      if (this._handler) this._eventStore.off('eventAppeared', this._handler);
      this._subscriptionDropped(this._eventStore, "catchup failed", err);
    }
  }

  async _onEventAppeared(eventData) {
    try {
      await this._eventAppeared(eventData)
    } catch (err) {
      this._logger.warn("eventAppeared handler failed", err);
      //TODO should this drop the subscription?
    }
  }

  stop() {
    if (this._handler) this._eventStore.off('eventAppeared', this._handler);
    if (this._subscriptionDropped) this._subscriptionDropped(this._eventStore, "user requested", null);
  }
}
