export const config = {
  key: 'studentId',
  schema: {
    studentId: {type: 'string', format: 'uuid', nullable: false},
    name: {type: 'string', nullable: false},
    licenseNo: {type: 'string', nullable: false},
    phoneNo: {type: 'string', nullable: false},
    email: {type: 'string', nullable: false},
    testDate: {type: 'string', nullable: false},
    testTime: {type: 'string', nullable: false},
    testLocation: {type: 'string', nullable: false},
    emergencyContactNo: {type: 'string', nullable: false},
    emergencyContactName: {type: 'string', nullable: false},
    allergies: {type: 'string', nullable: false}
  }
};

export async function handler(students, eventData) {
  const {typeId, event} = eventData;
  switch (typeId) {
    case 'StudentRegistered': {
      const {studentId, name, licenseNo, phoneNo, email, testDate, testTime, testLocation, emergencyContactNo, emergencyContactName, allergies} = event;
      students.create({studentId, name: `${name}`, licenseNo: `${licenseNo}`, phoneNo: `${phoneNo}`, email: `${email}`, testDate: `${testDate}`, testTime: `${testTime}`, testLocation: `${testLocation}`, emergencyContactNo: `${emergencyContactNo}`, emergencyContactName: `${emergencyContactName}`, allergies: `${allergies}`});
      break;
    }
    default:
      break;
  }
  return students;
}
