import {localCommand, localFind} from "../utils/api";

export const SKILL_ASSESSED = 'SKILL_ASSESSED';
export const SKILL_ASSESSED_SUCCESS = 'SKILL_ASSESSED_SUCCESS';
export const SKILL_ASSESSED_FAILED = 'SKILL_ASSESSED_FAILED';
export const assessSkill = localCommand('v1/assessment/AssessSkill', SKILL_ASSESSED, SKILL_ASSESSED_SUCCESS, SKILL_ASSESSED_FAILED);

export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const GET_ASSESSMENTS_SUCCESS = 'GET_ASSESSMENTS_SUCCESS';
export const GET_ASSESSMENTS_FAILED = 'GET_ASSESSMENTS_FAILED';
export const getAssessments = localFind('v1/assessment/find', GET_ASSESSMENTS, GET_ASSESSMENTS_SUCCESS, GET_ASSESSMENTS_FAILED);
