import {localFind, localFindOne} from "../utils/api";

export const GET_GLOBAL_SKILL = 'GET_GLOBAL_SKILL';
export const GET_GLOBAL_SKILL_SUCCESS = 'GET_GLOBAL_SKILL_SUCCESS';
export const GET_GLOBAL_SKILL_FAILED = 'GET_GLOBAL_SKILL_FAILED';
export const getGlobalSkill = localFindOne('v1/globalSkill/findOne', GET_GLOBAL_SKILL, GET_GLOBAL_SKILL_SUCCESS,
  GET_GLOBAL_SKILL_FAILED);

export const GET_GLOBAL_SKILLS = 'GET_GLOBAL_SKILLS';
export const GET_GLOBAL_SKILLS_SUCCESS = 'GET_GLOBAL_SKILLS_SUCCESS';
export const GET_GLOBAL_SKILLS_FAILED = 'GET_GLOBAL_SKILLS_FAILED';
export const getGlobalSkills = localFind('v1/globalSkill/find', GET_GLOBAL_SKILLS, GET_GLOBAL_SKILLS_SUCCESS,
  GET_GLOBAL_SKILLS_FAILED);
