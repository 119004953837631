import * as base from '../EventStore';
import Position from "./Position";

/**
 * @class
 */
export default class EventData extends base.EventStoreData {
  /**
   * @param {string} eventId
   * @param {string} eventType
   * @param {string} streamId
   * @param {number} eventNumber
   * @param {object} data
   * @param {object} [metadata]
   * @param {Position} [position]
   */
  constructor(eventId, eventType, streamId, eventNumber, data, metadata, position) {
    if (position && !(position instanceof Position)) throw new TypeError("position must be a Position");
    super(position, eventId, streamId, eventNumber, 0, eventType, data, metadata);
  }

  static fromObject(obj) {
    const {position, eventId, eventType, streamId, eventNumber, data, metadata} = obj;
    return new EventData(eventId, eventType, streamId, eventNumber, data, metadata, position);
  }
}
