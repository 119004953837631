import {Aggregate} from "../infra/aggregate";
import AddManeuverType from "../commands/AddManeuverType";
import ManeuverTypeAdded from "../events/ManeuverTypeAdded";
import {ValidationFailed} from "./errors";

export default class ManeuverType extends Aggregate {
  constructor() {
    super();

    this._registerCommandHandler(AddManeuverType, ManeuverType.addManeuverType);
    this._registerEventHandler(ManeuverTypeAdded, ManeuverType.onManeuverTypeAdded);
  }

  static addManeuverType(state, command) {
    const validationErrors = [];
    if (command.maneuverTypeId === '') validationErrors.push({field: 'maneuverTypeId', message: 'is required'});
    if (!command.maneuverIds.length) validationErrors.push({field: 'maneuverIds', message: 'is required'});
    if (command.name === '') validationErrors.push({field: 'name', message: 'is required'});
    if (validationErrors.length) throw new ValidationFailed(validationErrors);

    if (state.maneuverTypeId) throw new ValidationFailed('Maneuver Type already added');

    return [
      new ManeuverTypeAdded(command.maneuverTypeId, command.maneuverIds, command.name)
    ];
  }

  static onManeuverTypeAdded(state, event) {
    const {maneuverTypeId} = event;
    return {...state, maneuverTypeId};
  }
}