export default class AddGlobalSkill {
  static type = 'AddGlobalSkill';

  /**
   *
   * @param {!string} globalSkillId
   * @param {!string} name
   * @param {!boolean} isIndependent
   * @param {!boolean} isError
   */
  constructor(globalSkillId, name, isIndependent, isError) {
    if (typeof globalSkillId !== 'string') throw new TypeError("globalSkillId should be a string");
    if (typeof name !== 'string') throw new TypeError("name should be a string");
    if (typeof isIndependent !== 'boolean') throw new TypeError("isIndependent should be a boolean");
    if (typeof isError !== 'boolean') throw new TypeError("isError should be a boolean");
    this.globalSkillId = globalSkillId;
    this.name = name;
    this.isIndependent = isIndependent;
    this.isError = isError;
  }
}