import AuthController from './AuthController'
import SyncController from './SyncController'
import InstructorController from './InstructorController'
import LessonController from './LessonController'
import StudentController from "./StudentController";
import AssessmentController from "./AssessmentController";
import ManeuverController from "./ManeuverController";
import GlobalSkillController from "./GlobalSkillController";
import SkillOptionController from "./SkillOptionController";
import ManeuverTypeController from "./ManeuverTypeController";

const controllers = [
  AuthController,
  SyncController,
  InstructorController,
  LessonController,
  StudentController,
  AssessmentController,
  ManeuverController,
  GlobalSkillController,
  SkillOptionController,
  ManeuverTypeController,
];

export default controllers
