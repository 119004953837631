import {localCommand, localFindOne} from '../utils/api';

export const SYNC_FROM_SERVER = 'SYNC_FROM_SERVER';
export const SYNC_FROM_SERVER_SUCCESS = 'SYNC_FROM_SERVER_SUCCESS';
export const SYNC_FROM_SERVER_FAILED = 'SYNC_FROM_SERVER_FAILED';
export const syncFromServer = localCommand('v1/sync/syncFromServer', SYNC_FROM_SERVER, SYNC_FROM_SERVER_SUCCESS, SYNC_FROM_SERVER_FAILED);

export const SYNC_TO_SERVER = 'SYNC_TO_SERVER';
export const SYNC_TO_SERVER_SUCCESS = 'SYNC_TO_SERVER_SUCCESS';
export const SYNC_TO_SERVER_FAILED = 'SYNC_TO_SERVER_FAILED';
export const syncToServer = localCommand('v1/sync/syncToServer', SYNC_TO_SERVER, SYNC_TO_SERVER_SUCCESS, SYNC_TO_SERVER_FAILED);

export const SYNC_GET_INFO = 'SYNC_GET_INFO';
export const SYNC_GET_INFO_SUCCESS = 'SYNC_GET_INFO_SUCCESS';
export const SYNC_GET_INFO_FAILED = 'SYNC_GET_INFO_FAILED';
export const getSyncInfo = localFindOne('v1/sync/info', SYNC_GET_INFO, SYNC_GET_INFO_SUCCESS, SYNC_GET_INFO_FAILED);

export const SYNC_RESET_DEVICE = 'SYNC_RESET_DEVICE';
export const SYNC_RESET_DEVICE_SUCCESS = 'SYNC_RESET_DEVICE_SUCCESS';
export const SYNC_RESET_DEVICE_FAILED = 'SYNC_RESET_DEVICE_FAILED';
export const resetDevice = localCommand('v1/sync/resetDevice', SYNC_RESET_DEVICE, SYNC_RESET_DEVICE_SUCCESS, SYNC_RESET_DEVICE_FAILED);
