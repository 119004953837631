import ManeuverAdded from "../events/ManeuverAdded";

export const config = {
  key: 'maneuverId',
  schema: {
    maneuverId: {type: 'string', format: 'uuid', nullable: false},
    name: {type: 'string', nullable: false},
    createdAt: {type: 'number', nullable: false},
  }
};

export async function handler(skills, {typeId, event, metadata}) {
  const {timestamp} = metadata;
  switch (typeId) {
    case ManeuverAdded.type: {
      const {maneuverId, name} = event;
      skills.create({maneuverId, name, createdAt: timestamp});
      break;
    }
    default:
  }
  return skills;
}
