export default class InstructorController {
  constructor({buildAppRouter, readRepository}) {
    this._router = buildAppRouter('instructor', 1);

    this._router.findByFilter('find', async({user, query}) => {
      if (!user) return this._router.unauthorized();
      if (!user.roles.includes('admin')) return this._router.forbidden();
      return (await readRepository.findByFilter('instructors', {}));
    })

    this._router.findOne('findOne', async({user, query}) => {
      if (!user) return this._router.unauthorized();
      const {instructorId} = query;
      if (instructorId !== user.userId) return this._router.forbidden();

      return (await readRepository.findOne('instructors', {instructorId}));
    });
  }
}
