import ManeuverTypeAdded from "../events/ManeuverTypeAdded";
import ManeuverAdded from "../events/ManeuverAdded";

export const config = {
  key: 'maneuverTypeId',
  schema: {
    maneuverTypeId: {type: 'string', format: 'uuid', nullable: false},
    maneuverIds: {type: 'array', items: {type: 'string'}, nullable: false},
    maneuverNames: {type: 'array', items: {type: 'string'}, nullable: false},
    name: {type: 'string', nullable: false},
    createdAt: {type: 'number', nullable: false},
  }
};

export const lookups = {
  maneuvers: {
    key: 'maneuverId',
    schema: {
      maneuverId: {type: 'string', format: 'uuid', nullable: false},
      name: {type: 'string', nullable: false},
    }
  }
};

export async function handler(scores, {typeId, event, metadata}, {maneuvers}) {
  const {timestamp} = metadata;
  switch (typeId) {
    case ManeuverTypeAdded.type: {
      const {maneuverTypeId, maneuverIds, name} = event;
      const maneuverNames = (await maneuvers.findWhere({maneuverId: {inq: maneuverIds}})).map(({name}) => name);
      scores.create({maneuverTypeId, maneuverIds, maneuverNames, name, createdAt: timestamp});
      break;
    }
    case ManeuverAdded.type: {
      const {maneuverId, name} = event;
      maneuvers.create({maneuverId, name});
      break;
    }
    default:
  }
  return scores;
}
