import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Row, Col, Button} from 'react-bootstrap';
import './HomePage.css';
import {bindActionCreators} from "redux";
import {setPageTitle} from "../actions/nav";

export class HomePage extends Component {
  static propTypes = {
    username: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.setPageTitle("");
    this.firstButton && this.firstButton.focus();
  }

  render () {
    const { username, history, auth } = this.props;
    const isAdmin = auth.hasRole('admin');
    return (
      <div id="HomePage">
        <Row>
          <Col xs={12}>
            Welcome {username}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {this.renderLesson()}
          </Col>
        </Row>
        {isAdmin && <Row>
          <Col xs={12}>
            <Button onClick={() => history.push("/sync")}>Sync with server</Button>
          </Col>
        </Row>}
      </div>
    )
  }

  renderLesson() {
    const {lessonId, history} = this.props;
    if (lessonId) {
      return <Button ref={ref => this.firstButton = ref} onClick={() => history.push("/lesson/assessment")}>Continue Lesson</Button>;
    }
    return <Button ref={ref => this.firstButton = ref} onClick={() => history.push("/lesson/new")}>New Lesson</Button>;
  }
}

const mapStateToProps = state => ({
  username: state.auth.username,
  auth: state.auth.auth,
  lessonId: state.assessment.lessonId,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  setPageTitle,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
