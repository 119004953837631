import uuid from'uuid';
import AddManeuverType from "../commands/AddManeuverType";
import ManeuverType from "../domain/ManeuverType";

export default class ManeuverTypeController {
  /**
   * @param buildAppRouter
   * @param readRepository
   * @param {commandHandler} commandHandler
   */
  constructor({buildAppRouter, readRepository, commandHandler}) {
    this._router = buildAppRouter('maneuverType', 1);
    this._readRepository = readRepository;
    this._commandHandler = commandHandler;

    this._router.command(AddManeuverType, this.addManeuverType.bind(this));
    this._router.findByFilter('find', this.find.bind(this));
  }

  async addManeuverType({user, body}) {
    if (!user) return this._router.unauthorized();
    const {maneuverIds, name} = body;
    const cmd = new AddManeuverType(uuid.v4(), maneuverIds, name);
    await this._commandHandler(ManeuverType, cmd.maneuverTypeId, cmd);
    return(cmd.maneuverTypeId);
  }

  async find({user, query}) {
    if (!user) return this._router.unauthorized();
    return this._readRepository.findByFilter('maneuverTypes', query);
  }
}