export const config = {
  key: 'userId',
  indexes: ['username'],
  schema: {
    userId: {type: 'string', format: 'uuid', nullable: false},
    username: {type: 'string', maxLength: 50, nullable: false},
    passwordHash: {type: 'string', nullable: false},
    roles: {type: 'array', items: {type: 'string'}},
  }
};

export async function handler(users, eventData) {
  const {typeId, event} = eventData;
  switch (typeId) {
    case 'InstructorAdded': {
      const {instructorId, username, passwordHash, roles} = event;
      users.create({userId: instructorId, username, passwordHash, roles});
      break;
    }
    default:
  }
  return users;
}
