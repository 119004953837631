import uuid from 'uuid'
import EventEmitter from 'eventemitter3';
import Dexie from 'dexie'
import EventData from './EventData'
import Subscription from './Subscription'
import Position from './Position'
import { WrongExpectedVersionError } from '../EventStore'

const DEFAULT_BATCH_SIZE = 100

/**
 * @class
 */
export default class EventStore extends EventEmitter {
  /**
   * @param {Logger} logger
   * @param {string} databaseName
   */
  constructor (logger, databaseName = 'events') {
    super()
    this._logger = logger
    this._db = new Dexie(databaseName)
    this._db.version(1).stores({
      events: '++position, &[eventId+streamId], &[streamId+eventNumber], streamId'
    })
  }

  _getStreamLatestEvents(streamId, expectedVersion) {
    return this._db.events.where({streamId}).offset(expectedVersion + 1).toArray();
  }

  async _getStreamInfo(streamId) {
    const lastEvent = await this._db.events.where({streamId}).last();
    return lastEvent
      ? {streamVersion: lastEvent.eventNumber, lastPosition: new Position(lastEvent.position)}
      : {streamVersion: EventStore.EMPTY, lastPosition: new Position(-1)};
  }

  /**
   * @param {string} streamId
   * @param {object|object[]} eventDatas
   * @param {number} [expectedVersion]
   * @param {object} [options]
   * @return {Promise<EventStorePosition>}
   */
  async appendToStream (streamId, eventDatas, expectedVersion = EventStore.EMPTY, options) {
    if (typeof streamId !== 'string') throw new TypeError('streamId must be a string')
    if (typeof expectedVersion !== 'number') throw new TypeError('expectedVersion must be a number')
    if (!Array.isArray(eventDatas)) eventDatas = [eventDatas];
    // TODO implement the ANY use-case
    if (expectedVersion < EventStore.EMPTY) throw new Error('Not implemented');

    const eventsDatas = eventDatas.map((x,i) => new EventData(x.eventId, x.eventType, x.streamId, expectedVersion + 1 + i, x.data, x.metadata));

    const [err, result] = await this._db.transaction('rw', this._db.events, async (trx) => {
      const {streamVersion, lastPosition} = await this._getStreamInfo(streamId);

      if (expectedVersion > streamVersion) {
        return [new WrongExpectedVersionError(expectedVersion, streamVersion)];
      }
      const expectDuplicates = (expectedVersion < streamVersion);
      const lastEvents = expectDuplicates ? await this._getStreamLatestEvents(streamId, expectedVersion) : [];
      if (expectDuplicates && eventsDatas.length !== lastEvents.length) {
        return [new WrongExpectedVersionError(expectedVersion, streamVersion)];
      }

      const eventsToPublish = []
      let duplicates = 0;
      for (let i = 0; i < eventsDatas.length; i++) {
        const ev = eventsDatas[i];
        try {
          const position = await this._db.events.add(ev)
          eventsToPublish.push(EventData.fromObject({ ...ev, position: new Position(position) }))
        } catch (err) {
          if (err.name !== 'ConstraintError') return [err];
          if (ev.eventId !== lastEvents[i].eventId) return [new WrongExpectedVersionError(expectedVersion, streamVersion)];
          duplicates++;
        }
      }
      if ((expectDuplicates && duplicates !== eventDatas.length) || (!expectDuplicates && duplicates > 0)) {
        return [new WrongExpectedVersionError(expectedVersion, streamVersion)];
      }
      setTimeout(async () => {
        for (const ev of eventsToPublish) {
          await this.emit('eventAppeared', ev)
        }
      }, 0)
      return [null, eventsToPublish.length ? eventsToPublish[eventsToPublish.length - 1].position : lastPosition];
    })
    if (err) throw err;
    return result;
  }

  /**
   * Save events in a stream
   * @param {string} streamId
   * @param {object[]} events
   * @param {number} [expectedVersion]
   * @param {object} [metadata]
   * @param {{username,password}} [credentials]
   * @param {object} [options]
   * @returns {Promise<number>}
   * @async
   */
  async save (streamId, events, expectedVersion = EventStore.ANY, metadata, credentials, options = {}) {
    if (typeof streamId !== 'string') throw new TypeError('streamId must be a string')
    if (typeof expectedVersion !== 'number') throw new TypeError('expectedVersion must be a number')
    if (!Array.isArray(events)) throw new TypeError('events must be an array')
    if (metadata && typeof metadata !== 'object') throw new TypeError('metadata must be an object')
    if (events.length < 1) throw new Error('events must contain at least one event')
    if (expectedVersion < EventStore.ANY) throw new RangeError('invalid value for expectedVersion')

    if (expectedVersion === EventStore.ANY) {
      expectedVersion = (await this._getStreamInfo(streamId)).streamVersion;
    }

    const eventsDatas = events.map((ev, i) => new EventData(uuid.v4(), ev.constructor.type, streamId, expectedVersion + i + 1, ev, metadata))

    const [err, result] = await this._db.transaction('rw', this._db.events, async (trx) => {
      const {streamVersion, lastPosition} = await this._getStreamInfo(streamId);

      if (expectedVersion > streamVersion) {
        return [new WrongExpectedVersionError(expectedVersion, streamVersion)];
      }
      const expectDuplicates = (expectedVersion < streamVersion);
      const lastEvents = expectDuplicates ? await this._getStreamLatestEvents(streamId, expectedVersion) : [];
      if (expectDuplicates && eventsDatas.length !== lastEvents.length) {
        return [new WrongExpectedVersionError(expectedVersion, streamVersion)];
      }

      const eventsToPublish = []
      let duplicates = 0;
      for (let i = 0; i < eventsDatas.length; i++) {
        const ev = eventsDatas[i];
        try {
          const position = await this._db.events.add(ev)
          eventsToPublish.push(EventData.fromObject({ ...ev, position: new Position(position) }))
        } catch (err) {
          if (err.name !== 'ConstraintError') return [err];
          if (ev.eventId !== lastEvents[i].eventId) return [new WrongExpectedVersionError(expectedVersion, streamVersion)];
          duplicates++;
        }
      }
      if ((expectDuplicates && duplicates !== eventsDatas.length) || (!expectDuplicates && duplicates > 0)) {
        return [new WrongExpectedVersionError(expectedVersion, streamVersion)];
      }
      setTimeout(async () => {
        for (const ev of eventsToPublish) {
          await this.emit('eventAppeared', ev)
        }
      }, 0)
      return [null, eventsToPublish.length ? eventsToPublish[eventsToPublish.length - 1].eventNumber : streamVersion];
    })
    if (err) throw err;
    return result;
  }

  /**
   * Read all batch
   * @param {EventStorePosition} fromPosition
   * @param {number} [count]
   * @param {{username,password}} credentials
   * @returns {Promise<{isEndOfStream:boolean,nextPosition:Position,events:EventData[]}>}
   * @async
   */
  async readAllBatch (fromPosition, count = DEFAULT_BATCH_SIZE, credentials) {
    if (!(fromPosition instanceof Position)) throw new TypeError('fromPosition must be a Position')
    if (typeof count !== 'number') throw new TypeError('count must be a number')

    const events = (await this._db.events.offset(Math.max(fromPosition.value - 1, 0)).limit(count).toArray())
      .map(({ position, ...rest }) => EventData.fromObject({ position: new Position(position), ...rest }))
    return {
      isEndOfStream: events.length < count,
      nextPosition: events.length ? new Position(events[events.length - 1].position.value + 1) : fromPosition,
      events
    }
  }

  /**
   * Read a stream
   * @param {string} streamId
   * @param {number} [start]
   * @param {{username,password}} [credentials]
   * @returns {Promise<EventData[]>}
   * @async
   */
  async read (streamId, start = 0, credentials) {
    if (typeof streamId !== 'string') throw new TypeError('streamId must be a string')
    if (typeof start !== 'number') throw new TypeError('start must be a number')

    return (await this._db.events.where({ streamId }).offset(start).toArray())
      .map(({ position, ...rest }) => EventData.fromObject({ position: new Position(position), ...rest }))
  }

  async readBatch() {
    throw new Error('Not implemented')
  }

  /**
   * Subscribe to all from
   * @param {EventStorePosition|null} fromPosition
   * @param {EventStore~onEventAppeared} eventAppeared
   * @param {EventStore~onLiveProcessingStarted} liveProcessingStarted
   * @param {EventStore~onSubscriptionDropped} subscriptionDropped
   * @param {{username,password}} credentials
   * @param {number} [batchSize]
   * @returns {Subscription}
   */
  subscribeToAllFrom (fromPosition, eventAppeared, liveProcessingStarted, subscriptionDropped, credentials, batchSize) {
    const subscription = new Subscription(this, this._logger, this.createPosition(fromPosition), eventAppeared, liveProcessingStarted, subscriptionDropped)
    subscription.start()
    return subscription
  }

  /**
   * Create a position from
   * @param {number|Position|object|null} [any]
   * returns {EventStorePosition}
   */
  createPosition (any) {
    if (typeof any === 'undefined' || any === null) {
      return new Position(0)
    }
    if ((any instanceof Position) || (typeof any === 'object' && typeof any.value === 'number')) {
      return new Position(any.value)
    }
    if (typeof any === 'number') {
      return new Position(any)
    }
    throw new TypeError('invalid value for any')
  }

  async resetData() {
    await this._db.delete()
    return this._db.open()
  }
}
EventStore.ANY = -2
EventStore.EMPTY = -1
EventStore.START_POSITION = 0;
