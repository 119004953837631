import {GET_INSTRUCTORS, GET_INSTRUCTORS_FAILED, GET_INSTRUCTORS_SUCCESS} from '../actions/instructors';
import {errorMessageFromPayload} from '../utils/errors';
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  list: [],
  loading: false,
  error: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_INSTRUCTORS: {
      return {...state, loading: true};
    }
    case GET_INSTRUCTORS_SUCCESS: {
      return {...state, loading: false, list: action.payload};
    }
    case GET_INSTRUCTORS_FAILED: {
      return {...state, loading: false, error: errorMessageFromPayload(action.payload)};
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
