import { openIndexedDB, waitATick } from './utils'

/**
 * @class
 * @property {string} _dbName
 * @property {IndexedDB} _db
 */
export class DBPool {
  /**
   * @param {string} dbName
   */
  constructor (dbName) {
    this._dbName = dbName
    this._db = null
  }

  getConnection () {
    return this
  }

  release () {
  }

  async table (tableName) {
    await this._ensureOpenedForTrx()
    return this._db.table(tableName)
  }

  async exists (tableName) {
    await this._ensureOpenedForUpgrade()
    return this._db.exists(tableName)
  }

  async deleteTable (tableName) {
    await this._ensureOpenedForUpgrade()
    return this._db.deleteTable(tableName)
  }

  async createTable (tableName, keyPath, indexes) {
    await this._ensureOpenedForUpgrade()
    return this._db.createTable(tableName, keyPath, indexes)
  }

  async beginTransaction (mode) {
    await this._ensureOpenedForTrx()
    return this._db.beginTransaction(mode)
  }

  async endTransaction () {
    return this._db.endTransaction()
  }

  async resetData() {
    await this._ensureOpenedForTrx()
    return this._db.resetData()
  }

  async _ensureOpenedForTrx () {
    if (this._db && this._db.upgrading === false) return
    if (this._db) await this._db.close()
    //console.log('DbPool._ensureOpenedForTrx')
    await waitATick()
    const db = await openIndexedDB(this._dbName)
    this._db = new IndexedDB(db)
  }

  async _ensureOpenedForUpgrade () {
    if (this._db && this._db.upgrading === true) return
    if (this._db) await this._db.close()
    //console.log('DbPool._ensureOpenedForUpgrade')
    await waitATick()
    const db = await openIndexedDB(this._dbName, Date.now())
    this._db = new IndexedDB(db, true)
  }
}

class IndexedDB {
  /**
   * @param {IDBDatabase} db
   * @param {boolean} [upgrading]
   */
  constructor (db, upgrading = false) {
    this._db = db
    this.upgrading = upgrading
    this._transaction = null
  }

  async close () {
    //console.log('IndexedDB.close', this._db.name, this._db.version)
    return this._db.close()
  }

  beginTransaction (mode) {
    if (this.upgrading) throw new Error('beginTransaction requires non-upgrade mode')
    if (this._transaction) throw new Error('there\'s already an active transaction')
    this._transaction = this._db.transaction(this._db.objectStoreNames, mode)
  }

  endTransaction (rollback = false) {
    if (this.upgrading) throw new Error('beginTransaction requires non-upgrade mode')
    if (!this._transaction) throw new Error('no active transaction')
    if (rollback) this._transaction.abort()
    this._transaction = null
  }

  table (tableName) {
    if (this.upgrading) throw new Error('table requires non-upgrade mode')
    return new DBTable(this._db, tableName, this._transaction)
  }

  exists (tableName) {
    return containsString(this._db.objectStoreNames, tableName)
  }

  deleteTable (tableName) {
    if (!this.upgrading) throw new Error('deleteTable requires upgrade mode')
    return this._db.deleteObjectStore(tableName)
  }

  createTable (tableName, primaryKey, indexes) {
    if (!this.upgrading) throw new Error('createTable requires upgrade mode')
    const keyPath = (primaryKey.length === 1) ? primaryKey[0] : primaryKey
    const objectStore = this._db.createObjectStore(tableName, { keyPath })
    for (const index of indexes) {
      const keyPath = (index.length === 1) ? index[0] : index
      objectStore.createIndex(index.join('+'), keyPath, { unique: false })
    }
  }

  async resetData () {
    try {
      this.beginTransaction('readwrite')
      const promises = []
      for (let i = 0; i < this._db.objectStoreNames.length; i++) {
        promises.push(this.table(this._db.objectStoreNames[i]).clear())
      }
      await Promise.all(promises)
      this.endTransaction()
    } catch (err) {
      this.endTransaction(true)
    }
  }
}

class DBTable {
  /**
   * @param {IDBDatabase} db
   * @param {string} tableName
   * @param {IDBTransaction} trx
   */
  constructor (db, tableName, trx) {
    this._db = db
    this._tableName = tableName
    this._transaction = trx
  }

  _getOrCreateTrx (mode) {
    if (this._transaction) {
      if (!containsString(this._transaction.objectStoreNames, this._tableName)) {
        throw new Error(`a transaction is already opened but doesn't contains this objectStore: ${this._tableName} not in ${this._transaction.objectStoreNames}`)
      }
      if (this._transaction.mode !== mode) {
        throw new Error(`a transaction is already opened but with a different mode: ${mode} != ${this._transaction.mode}`)
      }
      return this._transaction
    }
    return this._db.transaction(this._tableName, mode)
  }

  // No use for add
  add () {
    throw new Error('Not implemented')
  }

  /**
   * @returns {Promise<void>}
   */
  clear () {
    return new Promise((resolve, reject) => {
      try {
        const trx = this._getOrCreateTrx('readwrite')
        const store = trx.objectStore(this._tableName)
        const req = store.clear()
        req.onerror = () => reject(req.error)
        req.onsuccess = () => resolve()
      } catch (err) {
        reject(err)
      }
    })
  }

  /**
   * @param {IDBValidKey|IDBKeyRange} [query]
   * @returns {Promise<number>}
   */
  count (query) {
    throw new Error('Not implemented')
  }

  /**
   * @param {IDBValidKey|IDBKeyRange} keyOrKeyRange
   * @returns {Promise<void>}
   */
  delete (keyOrKeyRange) {
    return new Promise((resolve, reject) => {
      try {
        const trx = this._getOrCreateTrx('readwrite')
        const store = trx.objectStore(this._tableName)
        const req = store.delete(keyOrKeyRange)
        req.onerror = () => reject(req.error)
        req.onsuccess = () => resolve()
      } catch (err) {
        reject(err)
      }
    })
  }

  /**
   * @param {IDBValidKey|IDBKeyRange} keyOrKeyRange
   * @returns {Promise<object>}
   */
  get (keyOrKeyRange) {
    return new Promise((resolve, reject) => {
      try {
        const trx = this._getOrCreateTrx('readonly')
        const store = trx.objectStore(this._tableName)
        const req = store.get(keyOrKeyRange)
        req.onerror = () => reject(req.error)
        req.onsuccess = () => resolve(req.result)
      } catch (err) {
        reject(err)
      }
    })
  }

  /**
   * @param {IDBValidKey|IDBKeyRange} keyOrKeyRange
   * @returns {Promise<IDBValidKey>}
   */
  getKey (keyOrKeyRange) {
    return new Promise((resolve, reject) => {
      try {
        const trx = this._getOrCreateTrx('readonly')
        const store = trx.objectStore(this._tableName)
        const req = store.getKey(keyOrKeyRange)
        req.onerror = () => reject(req.error)
        req.onsuccess = () => resolve(req.result)
      } catch (err) {
        reject(err)
      }
    })
  }

  /**
   * @param {IDBValidKey|IDBKeyRange} [query]
   * @param {number} [count]
   * @returns {Promise<object[]>}
   */
  getAll (query, count) {
    return new Promise((resolve, reject) => {
      try {
        const trx = this._getOrCreateTrx('readwrite')
        const store = trx.objectStore(this._tableName)
        const req = store.getAll(query, count)
        req.onerror = () => reject(req.error)
        req.onsuccess = () => resolve(req.result)
      } catch (err) {
        reject(err)
      }
    })
  }

  /**
   * @param {IDBValidKey|IDBKeyRange} [query]
   * @param {number} [count]
   * @returns {Promise<object[]>}
   */
  getAllKeys (query, count) {
    return new Promise((resolve, reject) => {
      try {
        const trx = this._getOrCreateTrx('readonly')
        const store = trx.objectStore(this._tableName)
        const req = store.getAllKeys(query, count)
        req.onerror = () => reject(req.error)
        req.onsuccess = () => resolve(req.result)
      } catch (err) {
        reject(err)
      }
    })
  }

  /**
   * @param {string} indexName
   * @returns {DBIndex}
   */
  index (indexName) {
    const trx = this._getOrCreateTrx('readwrite')
    const store = trx.objectStore(this._tableName);
    return new DBIndex(store.index(indexName), indexName)
  }

  openCursor () {
    throw new Error('Not implemented')
  }

  openKeyCursor () {
    throw new Error('Not implemented')
  }

  /**
   * @param {object} item
   * @param {*} [key]
   * @returns {Promise<void>}
   */
  put (item, key) {
    return new Promise((resolve, reject) => {
      try {
        const trx = this._getOrCreateTrx('readwrite')
        const store = trx.objectStore(this._tableName)
        const req = store.put(item, key)
        req.onerror = () => reject(req.error)
        req.onsuccess = () => resolve()
      } catch (err) {
        reject(err)
      }
    })
  }
}

class DBIndex {
  /**
   * @param {IDBIndex} index
   * @param {string} name
   */
  constructor (index, name) {
    this._index = index;
    this._name = name;
  }

  /**
   * @param {IDBValidKey|IDBKeyRange} [key]
   * @returns {Promise<number>}
   */
  count(key) {
    throw new Error('Not implemented')
  }

  /**
   * @param {IDBValidKey|IDBKeyRange} [keyOrKeyRange]
   * @returns {Promise<object>}
   */
  get(keyOrKeyRange) {
    return new Promise((resolve, reject) => {
      try {
        const req = this._index.get(keyOrKeyRange)
        req.onerror = () => reject(req.error)
        req.onsuccess = () => resolve(req.result)
      } catch (err) {
        reject(err)
      }
    })
  }

  /**
   * @param {IDBValidKey|IDBKeyRange} [key]
   * @returns {Promise<IDBValidKey>}
   */
  getKey(key) {
    throw new Error('Not implemented')
  }

  /**
   * @param {IDBValidKey|IDBKeyRange} [query]
   * @param {number} [count]
   * @returns {Promise<object[]>}
   */
  getAll(query, count) {
    return new Promise((resolve, reject) => {
      try {
        const req = this._index.getAll(query, count)
        req.onerror = () => reject(req.error)
        req.onsuccess = () => resolve(req.result)
      } catch (err) {
        reject(err)
      }
    })
  }

  openCursor() {
    throw new Error('Not implemented')
  }
  openKeyCursor() {
    throw new Error('Not implemented')
  }

}

function containsString (list, str) {
  for (let i = 0; i < list.length; i++) {
    if (list[i] === str) return true
  }
  return false
}
