/**
 * @param {ModelDefinition[]} modelsDefs
 */
export function toDexieSchema (modelsDefs) {
  return modelsDefs.reduce((a,c) => {
    const pk = c.primaryKey.length > 1 ? `[${c.primaryKey.join('+')}]` : c.primaryKey[0]
    const keys = [pk];
    if (c.indexes) {
      for (const index of c.indexes)
        keys.push(index.length > 1 ? `[${index.join('+')}]` : index);
    }
    a[c.name] = keys.join()
    return a;
  }, {})
}

// returns: undefined, object, string, number, boolean, array, null
export function typeOf(v) {
  if (v === null) return 'null';
  const type = typeof v;
  if (type === 'object' && Array.isArray(v)) return 'array';
  return type;
}

const idb = window.indexedDB ? window.indexedDB : global.indexedDB;

/**
 * @param {string} dbName
 * @returns {Promise<string[]>}
 */
export function createDefaultStores (dbName) {
  return new Promise((resolve, reject) => {
    const req = idb.open(dbName)
    req.onerror = () => reject(req.error)
    req.onblocked = () => reject(new Error('App can\'t upgrade because you have another instance opened'))
    req.onupgradeneeded = (evt) => {
      //console.log('creating default stores...')
      const db = req.result
      if (evt.oldVersion === 0) {
        db.createObjectStore('$checkpoints', { keyPath: 'name' })
        db.createObjectStore('$hashes', { keyPath: 'name' })
        db.createObjectStore('$versions', { keyPath: 'name' })
      }
      db.close()
      resolve()
    }
    req.onsuccess = async () => {
      //console.log('default stores already created.')
      const db = req.result
      db.close()
      resolve()
    }
  })
}

export function openIndexedDB (dbName, version) {
  return new Promise((resolve, reject) => {
    //console.log('openIndexedDB', dbName, version)
    const req = idb.open(dbName, version)
    //let done = false;
    req.onerror = () => {
      //if (!done) console.log('openIndexedDB.onerror', dbName, version, req.error)
      //done = true;
      reject(req.error)
    }
    req.onblocked = () => {
      //console.log('openIndexedDB.onblocked', dbName, version)
      reject(new Error(`App can't upgrade ${dbName} to ${version} because you have another instance opened`))
    }
    req.onupgradeneeded = () => {
      //console.log('openIndexedDB.onupgradeneeded', dbName, version)
      //done = true;
      resolve(req.result)
    }
    req.onsuccess = async () => {
      //console.log('openIndexedDB.onsuccess', dbName, version)
      //done = true;
      resolve(req.result)
    }
  })
}

export function waitATick() {
  return new Promise(resolve => setTimeout(resolve, 0));
}
