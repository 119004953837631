import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button} from 'react-bootstrap';
import {getGlobalSkills} from "../actions/globalSkills";

export class GlobalSkillsPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    // state
    list: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    // action
    getGlobalSkills: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    await this.loadSkills();
  }

  loadSkills = async() => {
    try {
      await this.props.getGlobalSkills();
    } catch (err) {
      // nothing to do, error message is in error prop
    }
  };

  addClick = () => {
    this.props.history.push('/globalSkill/add');
  };

  render() {
    const {list} = this.props;
    return <div>
      <h3>Global Skills</h3>
      <Button onClick={this.addClick}>Add</Button>
      <table>
        <thead>
        <tr>
          <th>Name</th>
          <th>Score</th>
          <th>Error</th>
        </tr>
        </thead>
        <tbody>
        {list.map(({globalSkillId, name, isIndependent, isError}) => (
          <tr key={globalSkillId}>
            <td>{name}</td>
            <td>{isIndependent ? 1 : 0}</td>
            <td>{isError ? 'Y' : 'N'}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>;
  }
}

const mapStateToProps = state => ({
  ...state.globalSkills
});
const mapDispatchToProps = dispatch => bindActionCreators({
  getGlobalSkills,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSkillsPage);
