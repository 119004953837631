export default class CheckPointStore {
  /**
   * @param {Dexie} db
   * @param {string} key
   */
  constructor (db, key) {
    /** @type {Table} */
    this._db = db;
    this._key = key;
  }

  async get () {
    const table = await this._db.table('$checkpoints')
    const obj = await table.get(this._key)
    if (obj) return obj.value;
    return null
  }

  async put (value) {
    const table = await this._db.table('$checkpoints')
    return table.put({name: this._key, value})
  }
}
