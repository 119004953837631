export default class ManeuverAdded {
  static type = 'ManeuverAdded';

  /**
   * @param {!string} maneuverId
   * @param {!string} name
   */
  constructor(maneuverId, name) {
    if (typeof maneuverId !== 'string') throw new TypeError("maneuverId must be a string");
    if (typeof name !== 'string') throw new TypeError("name must be a string");
    this.maneuverId = maneuverId;
    this.name = name;
  }
}