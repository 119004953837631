import { typeOf } from './utils'

const ValidTypeForComparisionOperators = ['string', 'number', 'boolean', 'null']
const ValidTypeForOtherOperators = ['array']

/*
Where filter Loopback API compatible (https://loopback.io/doc/en/lb2/Where-filter.html)

field    => {key: {operator: value}}
eq short => {key: value}
and/or   => {operator: [{filter1},{filter2},...]}

In addition Where filter support legacy
"and" short => {field1: value, field2: {operator: value}}
*/
export class Where {
  constructor (where) {
    this._root = Where._normalize(where)
  }

  static fromFilter (filter) {
    return new Where(filter.where)
  }

  static _normalize (node) {
    if (!node) return node
    const keys = Object.keys(node)
    if (keys.length === 0) return node
    if (keys.length > 1) {
      return Where._normalizeAnd(node, keys)
    }
    const key = keys[0]
    const value = node[key]
    if (['$or', '$and', 'or', 'and'].includes(key) && Array.isArray(value)) {
      const op = Where._normalizeOp(key)
      return {[op]: value.map(Where._normalize)}
    }
    return Where._normalizeEq(key, node[key])
  }

  static _normalizeEq (key, value) {
    if (!value || typeof value !== 'object') {
      return {
        [key]: {'eq': value}
      }
    }
    const originalOp = Object.keys(value)[0]
    const op = Where._normalizeOp(originalOp)
    const v = value[originalOp]
    Where._validateOperatorAndValue(op, v)
    return {[key]: {[op]: v}}
  }

  static _validateOperatorAndValue (op, value) {
    const type = typeOf(value)
    switch (op) {
      case 'eq':
      case 'lt':
      case 'lte':
      case 'gt':
      case 'gte':
      case 'ilike':
      case 'neq': {
        if (!ValidTypeForComparisionOperators.includes(type)) {
          throw new Error(`Invalid type of value ${type} for operator ${op}`)
        }
        break
      }
      case 'between':
      case 'inq': {
        if (!ValidTypeForOtherOperators.includes(type)) {
          throw new Error(`Invalid type of value ${type} for operator ${op}`)
        }
        break
      }
      default: {
        throw new Error(`Invalid operator ${op}`)
      }
    }
  }

  static _normalizeAnd (node, keys) {
    const nodes = keys.map(key => Where._normalizeEq(key, node[key]))
    return {
      'and': nodes
    }
  }

  static _normalizeOp (originalOp) {
    if (!originalOp) return null
    let op = originalOp.toLowerCase()
    if (op[0] === '$') op = op.substr(1)
    return op
  }

  isEmptyOrNull () {
    return (!this._root || Object.keys(this._root).length === 0)
  }

  rootNode () {
    return this._root
  }

  /**
   * @param {string[]} index
   * @returns {ConstraintNode[]}
   * The result will be an empty array if nothing was match otherwise array size will match index size
   */
  getIndexConstraint(index) {
    const result = index.map(x => this._getKeyConstraint(x))
    if (result.filter(x => x !== null).length === 0) return [];
    return result;
  }

  _getKeyConstraint(key) {
    const firstNode = this._getFirstNode();
    if (firstNode === null) return null;
    if (firstNode.key === key) return firstNode;
    if (firstNode.operator === 'and') {
      return firstNode.value
        .map(x => Where._readNode(x))
        .find(x => x.key === key) || null
    }
    // or use-case are too complex and rare so we don't handle them for now
    return null
  }

  _getFirstNode () {
    if (!this._root) {
      return null
    }
    return Where._readNode(this._root);
  }

  static _readNode(node) {
    const keys = Object.keys(node)
    if (keys.length === 0) {
      return null
    }
    const key = keys[0]
    const value = node[key]
    if (Array.isArray(value)) {
      return new ConstraintNode(null, key, value)
    }
    const operator = Object.keys(value)[0]
    return new ConstraintNode(key, operator, value[operator])
  }
}

class ConstraintNode {
  /**
   * @param {?string} key
   * @param {string} operator
   * @param value
   */
  constructor (key, operator, value) {
    this.key = key
    this.operator = operator
    this.value = value
    Object.freeze(this)
  }
}

const Directions = {
  'ASC': 1,
  'DESC': -1
}

export class Order {
  static fromFilter (filter) {
    return new Order(filter.order)
  }

  constructor (order) {
    this._order = order
  }

  static _parseOrderValue (order) {
    const [propertyName, direction] = order.split(' ')
    const dir = direction && Directions[direction.toUpperCase()]
    return [propertyName, dir || Directions.ASC]
  }

  getOrders () {
    if (!this._order) {
      return []
    }
    if (typeof this._order === 'string') {
      return [Order._parseOrderValue(this._order)]
    }
    if (Array.isArray(this._order)) {
      return this._order.map(Order._parseOrderValue)
    }
    throw new Error(`Invalid type for order.`)
  }
}
