import {REGISTER_STUDENT, REGISTER_STUDENT_FAILED, REGISTER_STUDENT_SUCCESS} from '../actions/students';
import {errorMessageFromPayload} from '../utils/errors';
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  adding: false,
  error: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case REGISTER_STUDENT: {
      return {...state, adding: true};
    }
    case REGISTER_STUDENT_SUCCESS: {
      return {...state, adding: false, error: ''};
    }
    case REGISTER_STUDENT_FAILED: {
      return {...state, adding: false, error: errorMessageFromPayload(action.payload)};
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
