import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {BrowserRouter as Router, Route, Switch, Link, useHistory} from 'react-router-dom'
import {Container, Nav, Navbar, Alert} from 'react-bootstrap'
import {version} from './version';
import {logoutUser} from './actions/auth'
import {hideAlert} from "./actions/appUpdate";
import auth from './utils/auth'
import HomePage from './components/HomePage'
import LoginPage from './components/LoginPage'
import PrivateRoute from './components/PrivateRoute'
import SyncPage from './components/SyncPage'
import AddInstructor from './components/AddInstructor'
import InstructorsPage from './components/InstructorsPage'
import RegisterStudent from './components/RegisterStudent'
import CompleteLessonPage from "./components/CompleteLessonPage";
import SkillAssessedPage from "./components/AssessmentPage";
import AddManeuverPage from "./components/AddManeuverPage";
import ManeuversPage from "./components/ManeuversPage";
import AddGlobalSkillPage from "./components/AddGlobalSkillPage";
import GlobalSkillsPage from "./components/GlobalSkillsPage";
import SkillOptionsPage from "./components/SkillOptionsPage";
import AddSkillOptionPage from "./components/AddSkillOptionPage";
import ManeuverTypesPage from "./components/ManeuverTypesPage";
import AddManeuverTypePage from "./components/AddManeuverTypePage";
import StudentSignUpPage from "./components/StudentSignUpPage";
import LessonSummaryPage from "./components/LessonSummaryPage"
import LessonsSummaryPage from "./components/LessonsSummaryPage"
import 'react-bootstrap-typeahead/css/Typeahead.css';

const styles = {
  legal: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    fontSize: '10pt',
    width: '100%',
    textAlign: 'center',
    background: 'white'
  },
  appUpdateAlert: {
    position: 'absolute',
    top: 5,
    left: 5,
    right: 5,
    width: 'auto'
  }
};

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false,
    };
    this._auth = auth();
  }

  componentWillMount() {
    this._auth.set(this.props.auth);
  }

  componentWillUnmount() {
    this._removeHistoryListener && this._removeHistoryListener();
  }

  componentWillUpdate(nextProps) {
    this._auth.set(nextProps.auth);
  }

  onRefreshClick = (e) => {
    e.preventDefault();
    window.location.reload(true);
  };

  logout = () => {
    this.props.logoutUser();
  };

  onNavToggle = () => this.setState({navExpanded: !this.state.navExpanded});

  navWrapper = ({children}) => {
    if (!this._removeHistoryListener) {
      this._removeHistoryListener = useHistory().listen(this.closeNavMenu);
    }
    return children;
  };

  closeNavMenu = () => this.state.navExpanded && this.setState({navExpanded: false});

  render() {
    const {auth, title, hideAlert, newAppContentAvailable, hideNewContentAlert} = this.props;
    const isAdmin = auth.hasRole("admin");
    return (
      <Router basename="/">
        <Container fluid className="h-100">
          <this.navWrapper>
            <Navbar bg="light" expand="xxl" expanded={this.state.navExpanded} onToggle={this.onNavToggle}>
              <Navbar.Brand as={Link} to="#home">{title}</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link eventKey={1} as={Link} to="/">Home</Nav.Link>
                  {isAdmin && <Nav.Link eventKey={3} as={Link} to="/instructor/list">Instructors</Nav.Link>}
                  {isAdmin && <Nav.Link eventKey={4} as={Link} to="/maneuvers">ICBC Maneuvers</Nav.Link>}
                  {isAdmin && <Nav.Link eventKey={5} as={Link} to="/maneuverTypes">Maneuver Types / Traffic Options</Nav.Link>}
                  {isAdmin && <Nav.Link eventKey={6} as={Link} to="/globalSkills">Global Skills</Nav.Link>}
                  {isAdmin && <Nav.Link eventKey={7} as={Link} to="/skillOptions">Comments</Nav.Link>}
                  <Nav.Link eventKey={10} as={Link} to="/" onSelect={this.logout}>Logout</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </this.navWrapper>
          <Container fluid style={{paddingBottom: '2em'}} onClick={this.closeNavMenu}>
            <Switch>
              <PrivateRoute exact path="/" component={HomePage}/>
              <Route exact path="/login" component={LoginPage}/>
              {/* Instructor */}
              <PrivateRoute exact path="/lesson/new" component={StudentSignUpPage}/>
              <PrivateRoute exact path="/lesson/assessment" component={SkillAssessedPage}/>
              <PrivateRoute exact path="/lesson/end" component={CompleteLessonPage}/>
              <PrivateRoute exact path="/lesson/summary" component={LessonSummaryPage}/>
              <PrivateRoute exact path="/lessons/summary" component={LessonsSummaryPage}/>
              {/* Admin */}
              <PrivateRoute exact path="/sync" requiresRole="admin" component={SyncPage}/>
              <PrivateRoute exact path="/instructor/list" requiresRole="admin" component={InstructorsPage}/>
              <PrivateRoute exact path="/instructor/add" requiresRole="admin" component={AddInstructor}/>
              <PrivateRoute exact path="/student/register" requiresRole="admin" component={RegisterStudent}/>
              <PrivateRoute exact path="/maneuvers/add" requiresRole="admin" component={AddManeuverPage}/>
              <PrivateRoute exact path="/maneuvers" requiresRole="admin" component={ManeuversPage}/>
              <PrivateRoute exact path="/globalSkill/add" requiresRole="admin" component={AddGlobalSkillPage}/>
              <PrivateRoute exact path="/globalSkills" requiresRole="admin" component={GlobalSkillsPage}/>
              <PrivateRoute exact path="/skillOption/add" requiresRole="admin" component={AddSkillOptionPage}/>
              <PrivateRoute exact path="/skillOptions" requiresRole="admin" component={SkillOptionsPage}/>
              <PrivateRoute exact path="/maneuverTypes/add" requiresRole="admin" component={AddManeuverTypePage}/>
              <PrivateRoute exact path="/maneuverTypes" requiresRole="admin" component={ManeuverTypesPage}/>
            </Switch>
          </Container>
          <div style={styles.legal}>
            &copy; {new Date().getFullYear()} RainCity Driving School ({version})
          </div>
          <Alert style={styles.appUpdateAlert} variant="info" show={newAppContentAvailable && !hideNewContentAlert} onClose={() => hideAlert()} dismissible>
            <Alert.Heading>Update Available!</Alert.Heading>
            <p>
              Please close all open tabs of Rain City and open a new one to see the update.
            </p>
          </Alert>
        </Container>
      </Router>
    )
  }
}

const mapStateToProps = state => ({
  title: state.nav.title,
  auth: state.auth.auth,
  newAppContentAvailable: state.appUpdate.newAppContentAvailable,
  hideNewContentAlert: state.appUpdate.hideNewContentAlert
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logoutUser,
  hideAlert
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App)
