import {SET_PAGE_TITLE} from '../actions/nav';

const defaultState = {
  title: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PAGE_TITLE: {
      const {title} = action;
      return {...state, title};
    }
    default:
      return state;
  }
}
