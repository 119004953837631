import {errorMessageFromPayload} from "../utils/errors";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";
import {ADD_MANEUVER_TYPE, ADD_MANEUVER_TYPE_FAILED, ADD_MANEUVER_TYPE_SUCCEEDED} from "../actions/addManeuverType";
import {GET_MANEUVERS_SUCCEEDED} from "../actions/maneuvers";

const defaultState = {
  adding: false,
  error: '',
  maneuvers: [],
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_MANEUVER_TYPE: {
      return {
        ...state,
        adding: true,
        error: '',
      };
    }
    case ADD_MANEUVER_TYPE_SUCCEEDED: {
      return {
        ...state,
        adding: false,
      };
    }
    case ADD_MANEUVER_TYPE_FAILED: {
      return {
        ...state,
        adding: false,
        error: errorMessageFromPayload(action.payload),
      };
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    case GET_MANEUVERS_SUCCEEDED: {
      return {
        ...state,
        maneuvers: action.payload,
      }
    }
    default:
      return state;
  }
}
