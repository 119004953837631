import GlobalSkillAdded from "../events/GlobalSkillAdded";

export const config = {
  key: 'globalSkillId',
  schema: {
    globalSkillId: {type: 'string', format: 'uuid', nullable: false},
    name: {type: 'string', nullable: false},
    isIndependent: {type: 'boolean', nullable: false},
    isError: {type: 'boolean', nullable: false},
    createdAt: {type: 'number', nullable: false},
  }
};

export async function handler(globalSkills, {typeId, event, metadata}) {
  const {timestamp} = metadata;
  switch (typeId) {
    case GlobalSkillAdded.type: {
      const {globalSkillId, name, isIndependent, isError} = event;
      globalSkills.create({globalSkillId, name, isIndependent, isError, createdAt: timestamp});
      break;
    }
    default:
      break;
  }
  return globalSkills;
}
