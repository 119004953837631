import {Aggregate} from "../infra/aggregate";
import AddGlobalSkill from "../commands/AddGlobalSkill";
import GlobalSkillAdded from "../events/GlobalSkillAdded";
import {ValidationFailed} from "./errors";

export default class GlobalSkill extends Aggregate {
  constructor() {
    super();

    this._registerCommandHandler(AddGlobalSkill, GlobalSkill.addGlobalSkill);
    this._registerEventHandler(GlobalSkillAdded, GlobalSkill.onGlobalSkillAdded);
  }

  static addGlobalSkill(state, command) {
    const validationErrors = [];
    if (command.globalSkillId === '') validationErrors.push({field: 'globalSkillId', message: 'is required'});
    if (command.name === '') validationErrors.push({field: 'name', message: 'is required'});
    if (command.isIndependent && command.isError) validationErrors.push({field: 'isError', message: "can't be set if isIndependent"});
    if (validationErrors.length) throw new ValidationFailed(validationErrors);

    if (state.globalSkillId) throw new ValidationFailed('Global skill already added');

    return [
      new GlobalSkillAdded(command.globalSkillId, command.name, command.isIndependent, command.isError)
    ];
  }

  static onGlobalSkillAdded(state, event) {
    const {globalSkillId} = event;
    return {...state, globalSkillId};
  }
}