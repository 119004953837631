import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Row, Col, Button, Toast} from "react-bootstrap";
import {setPageTitle} from "../actions/nav";
import {getManeuvers} from "../actions/maneuvers";
import {getGlobalSkills} from "../actions/globalSkills";
import {getSkillOptions} from "../actions/skillOptions";
import {getManeuverTypes} from "../actions/maneuverTypes";
import {assessSkill} from "../actions/assessment";
import "./AssessmentPage.css";
import Student from "./Student";

function optionsFromHash(h, filter = x => true) {
  return Object.keys(h).filter(k => filter(h[k])).map(k => ({id: k, label: h[k].name}));
}

const SummaryId = '-end-';
const defaultState = {
  maneuverId: '',
  maneuverTypeId: '',
  globalSkillId: '',
  skillOptionId: '',
  step: '',
  options: [],
  focusedOption: 0,
  notificationMessage: ''
};

export class AssessmentPage extends Component {

  static propTypes = {
    // state
    maneuvers: PropTypes.object.isRequired,
    maneuverTypes: PropTypes.object.isRequired,
    globalSkills: PropTypes.object.isRequired,
    skillOptions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    // actions
    setPageTitle: PropTypes.func.isRequired,
    getManeuvers: PropTypes.func.isRequired,
    getManeuverTypes: PropTypes.func.isRequired,
  };

  optionsRefs = [];

  state = Object.assign({}, defaultState);

  async componentDidMount() {
    this.props.setPageTitle('Assessment');
    await Promise.all([
      this.props.getManeuvers({order: 'createdAt ASC'}),
      this.props.getManeuverTypes({order: 'createdAt ASC'}),
      this.props.getGlobalSkills({order: 'createdAt ASC'}),
      this.props.getSkillOptions({order: 'createdAt ASC'}),
    ]);
    await this.setOptions();
  }

  setOptions = async(id) => {
    if (id === SummaryId) {
      this.props.history.push('/lesson/summary');
      return;
    }

    const {step} = this.state;
    const {maneuvers, maneuverTypes, globalSkills, skillOptions} = this.props;
    switch (step) {
      case '': {
        this.setState({
          step: 'maneuver',
          options: optionsFromHash(maneuvers).concat([{id: SummaryId, label: 'Summary'}]),
          focusedOption: 0
        }, this.focusOption);
        break;
      }
      case 'maneuver': {
        this.setState({
          maneuverId: id,
          step: 'maneuverType',
          options: optionsFromHash(maneuverTypes, x => x.maneuverIds.includes(id)),
          focusedOption: 0
        }, this.focusOption);
        break;
      }
      case 'maneuverType': {
        this.setState({
          maneuverTypeId: id,
          step: 'globalSkill',
          options: optionsFromHash(globalSkills),
          focusedOption: 0
        }, this.focusOption);
        break;
      }
      case 'globalSkill': {
        this.setState({
          globalSkillId: id,
          step: 'skillOption',
          options: optionsFromHash(skillOptions, x => x.globalSkillId === id),
          focusedOption: 0
        }, this.focusOption);
        break;
      }
      case 'skillOption': {
        const {lessonId} = this.props;
        const {maneuverId, maneuverTypeId, globalSkillId} = this.state;
        try {
          await this.props.assessSkill({lessonId, maneuverId, maneuverTypeId, globalSkillId, skillOptionId: id});
          this.setState({
            notificationMessage: `${maneuvers[maneuverId].name} > ${maneuverTypes[maneuverTypeId].name} > ${globalSkills[globalSkillId].name} > ${skillOptions[id].name}`,
          });
          setTimeout(this.reset, 1500);
        } catch (err) {
        }
        break;
      }
      default:
        console.error('Invalid step:', step);
        break;
    }
  };

  focusOption = () => {
    const {focusedOption} = this.state;
    const optionRef = this.optionsRefs[focusedOption];
    optionRef && optionRef.focus();
  };

  reset = () => {
    this.setState({...defaultState}, this.setOptions);
  };

  goBack = () => {
    const {step, globalSkillId, maneuverId, maneuverTypeId} = this.state;
    const {globalSkills, maneuvers, maneuverTypes} = this.props;
    let changes = {};
    switch(step) {
      case 'maneuverType': {
        const options = optionsFromHash(maneuvers).concat([{id: SummaryId, label: 'Summary'}]);
        const focusedOption = options.findIndex(x => x.id === maneuverId);
        changes = {
          maneuverId: '',
          step: 'maneuver',
          focusedOption,
          options
        };
        break;
      }
      case 'globalSkill': {
        const options = optionsFromHash(maneuverTypes, x => x.maneuverIds.includes(maneuverId)).concat([{id: SummaryId, label: 'Summary'}]);
        const focusedOption = options.findIndex(x => x.id === maneuverTypeId);
        changes = {
          maneuverTypeId: '',
          step: 'maneuverType',
          focusedOption,
          options
        };
        break;
      }
      case 'skillOption': {
        const options = optionsFromHash(globalSkills);
        const focusedOption = options.findIndex(x => x.id === globalSkillId);
        changes = {
          globalSkillId: '',
          step: 'globalSkill',
          focusedOption,
          options
        };
        break;
      }
      default:
        return;
    }
    this.setState(changes, this.focusOption);
  };

  handleClick = (evt) => {
    evt.preventDefault();
    this.setOptions(evt.target.id);
  };

  handleKeyPress = evt => {
    evt.preventDefault();
    const {options, focusedOption} = this.state;
    switch(evt.which) {
      case 37: // left
        this.goBack();
        break;
      case 38: // up
        if (focusedOption > 0) {
          this.setState({focusedOption: focusedOption - 1}, this.focusOption);
        }
        break;
      case 39: // right
        this.setOptions(evt.target.id);
        break;
      case 40: // down
        if (focusedOption < options.length - 1) {
          this.setState({focusedOption: focusedOption + 1}, this.focusOption);
        }
        break;
      default:
        break;
    }
    return false;
  };

  render() {
    const {step, options, maneuverId, maneuverTypeId, globalSkillId, notificationMessage, focusedOption} = this.state;
    const {error, maneuvers, maneuverTypes, globalSkills} = this.props;
    const readOnly = step === 'skillOption' && notificationMessage !== '';

    return (
      <div id="AssessmentPage">
        <Student/>
        <Row>
          <Col xs={12}>
            {maneuverId && maneuvers[maneuverId].name}
            {maneuverTypeId && ` > ${maneuverTypes[maneuverTypeId].name}`}
            {globalSkillId && ` > ${globalSkills[globalSkillId].name}`}
          </Col>
        </Row>
        <Row className="error">
          <Col xs={12}>{error}</Col>
        </Row>
        {options.map(({label, id, variant}, index) => <Row key={id}>
          <Col xs={12}>
            <Button ref={ref => this.optionsRefs[index] = ref}
                    id={id}
                    disabled={readOnly} variant={focusedOption === index ? 'primary' : 'outline-primary'}
                    onKeyUp={this.handleKeyPress}
                    onClick={this.handleClick}
                    onBlur={this.focusOption}
            >
              {label}
            </Button>
          </Col>
        </Row>)}
        <Toast animation
               style={{position: 'absolute', bottom: 0, width: '100%'}}
               show={!!notificationMessage}>
          <Toast.Header closeButton={false}>
            <strong className="mr-auto">Choice recorded</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>{notificationMessage}</Toast.Body>
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.assessment,
  studentId: state.student.studentId,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setPageTitle,
  getManeuvers,
  getGlobalSkills,
  getSkillOptions,
  getManeuverTypes,
  assessSkill,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentPage);
