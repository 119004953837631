class NullAuth {
  isLoggedIn() { return false; }
  get accessToken() { throw new Error('Not logged in.') }
  get refreshToken() { throw new Error('Not logged in.') }
  get userId() { throw new Error('Not logged in.') }
  hasRole() { return false }
}

export class Auth {
  constructor(scope) {
    this._scope = scope;
    this._auth = new NullAuth();
  }

  set(auth) {
    this._auth = auth;
  }

  isLoggedIn() {
    return this._auth.isLoggedIn();
  }

  access_token() {
    return this._auth.accessToken;
  }

  scope() {
    return this._scope;
  }

  userId() {
    return this._auth.userId;
  }

  hasRole(role) {
    return this._auth.hasRole(role);
  }
}

let instances = {};
export default function auth(scope) {
  scope = scope || '';
  let instance = instances[scope];
  if (!instance) {
    instance = instances[scope] = new Auth(scope);
  }
  return instance;
}
