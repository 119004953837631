export const config = {
  key: 'instructorId',
  schema: {
    instructorId: {type: 'string', format: 'uuid', nullable: false},
    name: {type: 'string', nullable: false},
  }
};

export async function handler(users, eventData) {
  const {typeId, event} = eventData;
  switch (typeId) {
    case 'InstructorAdded': {
      const {instructorId, firstName, lastName} = event;
      users.create({instructorId, name: `${firstName}, ${lastName}`});
      break;
    }
    default:
  }
  return users;
}
