import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';
import {getStudent, getStudents, localRegisterStudent} from "../actions/students";
import {Typeahead} from 'react-bootstrap-typeahead';
import {Button, Row, Col, Form} from 'react-bootstrap';
import {setPageTitle} from "../actions/nav";
import './StudentSignUpPage.css'
import {startLesson} from "../actions/startLesson";

const defaultState = {
  selectedStudent: null,
  studentId: '',
  name: '',
  licenseNo: '',
  phoneNo: '',
  email: '',
  testDate: '',
  testTime: '',
  testLocation: '',
  emergencyContactNo: '',
  emergencyContactName: '',
  allergies: ''
};

export class StudentSignUpPage extends Component {
  static propTypes = {
    setPageTitle: PropTypes.func.isRequired,
    startLesson: PropTypes.func.isRequired,
    getStudents: PropTypes.func.isRequired,
    starting: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    students: PropTypes.array.isRequired,
    student: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {...defaultState};

  componentDidMount() {
    this.props.setPageTitle('Student Sign Up');
    this.props.getStudents();
  }

  render() {
    const {students, student, error} = this.props;
    const {studentId, selectedStudent} = this.state;

    const options = [{studentId: 'new'}, ...students];
    return (
      <div id="StudentSignUpPage">
        <div>
          <Typeahead id="StudentSelector"
                     placeholder="Select student"
                     autoFocus
                     selected={selectedStudent}
                     options={options}
                     labelKey={(s) => `${s.name || 'New Student'}${s.licenseNo ? ` (${s.licenseNo})` : ''}`}
                     onChange={this.onStudentChange} />
        </div>
        <div className="error">
          {error}
        </div>
        <div className="student-info">
        {studentId ?
          studentId === 'new' ? <Form>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <Form.Control ref={ref => this.firstFormField = ref} type="text" name="name" value={this.state.name} onChange={this.onFieldChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>License No:</Form.Label>
              <Form.Control type="text" name="licenseNo" value={this.state.licenseNo} onChange={this.onFieldChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone No:</Form.Label>
              <Form.Control type="text" name="phoneNo" value={this.state.phoneNo} onChange={this.onFieldChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email:</Form.Label>
              <Form.Control type="text" name="email" value={this.state.email} onChange={this.onFieldChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Test Date:</Form.Label>
              <Form.Control type="text" name="testDate" value={this.state.testDate} onChange={this.onFieldChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Test Time:</Form.Label>
              <Form.Control type="text" name="testTime" value={this.state.testTime} onChange={this.onFieldChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Test Location:</Form.Label>
              <Form.Control type="text" name="testLocation" value={this.state.testLocation} onChange={this.onFieldChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Emergency Contact Name:</Form.Label>
              <Form.Control type="text" name="emergencyContactName" value={this.state.emergencyContactName} onChange={this.onFieldChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Emergency Contact No:</Form.Label>
              <Form.Control type="text" name="emergencyContactNo" value={this.state.emergencyContactNo} onChange={this.onFieldChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Allergies:</Form.Label>
              <Form.Control type="text" name="allergies" value={this.state.allergies} onChange={this.onFieldChange} />
            </Form.Group>
          </Form> : <React.Fragment>
            <Row><Col xs={6}>Name:</Col><Col xs={6}>{student.name}</Col></Row>
            <Row><Col xs={6}>License No:</Col><Col xs={6}>{student.licenseNo}</Col></Row>
            <Row><Col xs={6}>Phone No:</Col><Col xs={6}>{student.phoneNo}</Col></Row>
            <Row><Col xs={6}>Email:</Col><Col xs={6} className="email">{student.email}</Col></Row>
            <Row><Col xs={6}>Test Date:</Col><Col xs={6}>{student.testDate}</Col></Row>
            <Row><Col xs={6}>Test Time:</Col><Col xs={6}>{student.testTime}</Col></Row>
            <Row><Col xs={6}>Test Location:</Col><Col xs={6}>{student.testLocation}</Col></Row>
            <Row><Col xs={6}>Emergency Contact Name:</Col><Col xs={6}>{student.emergencyContactName}</Col></Row>
            <Row><Col xs={6}>Emergency Contact No:</Col><Col xs={6}>{student.emergencyContactNo}</Col></Row>
            <Row><Col xs={6}>Allergies:</Col><Col xs={6}>{student.allergies}</Col></Row>
          </React.Fragment> : ''}
        </div>
        {studentId && <div className="buttons">
          <Button className="mx-2" onClick={() => this.props.history.push('/lessons/summary')}>Lessons Summary</Button>
          <Button className="mx-2" ref={ref => this.startLessonBtn = ref} onClick={this.startLesson}>New Lesson</Button>
        </div>}
      </div>
    );
  }

  onFieldChange = evt => this.setState({[evt.target.name]: evt.target.value});

  startLesson = async() => {
    //const instructorId = this.props.auth.userId;
    //const studentId = this.state.selectedStudent.id;
    const {auth: {userId: instructorId}} = this.props;
    let {studentId} = this.state;

    if (studentId === 'new') {
      const {studentId, selectedStudent, ...data} = this.state;
      try {
        await this.props.registerStudent(data);
      } catch (err) {
        return;
      }
    }

    studentId = this.props.student.studentId;
    try {
      await this.props.startLesson({instructorId, studentId});
    } catch (err) {
      return;
    }

    this.props.history.push('/lesson/assessment');
  };

  onStudentChange = (selected) => {
    const studentId = selected.length > 0 ? selected[0].studentId : '';
    if (studentId && studentId !== 'new') {
      this.props.getStudent({studentId});
    }
    this.setState({...defaultState, selected, studentId},
      () => {
        if (studentId === 'new') {
          this.firstFormField.focus();
        } else if (studentId) {
          this.startLessonBtn.focus();
        }
      });
  };
}

const mapStateToProps = state => ({
  ...state.startLesson,
  students: state.students.list,
  student: state.student,
  auth: state.auth.auth,
  error: state.student.error
});

const mapDispatchToProps = dispatch => bindActionCreators({
  startLesson,
  getStudents,
  getStudent,
  registerStudent: localRegisterStudent,
  setPageTitle,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentSignUpPage);
