import {
  GET_SKILL_OPTION,
  GET_SKILL_OPTION_SUCCESS,
  GET_SKILL_OPTION_FAILED,
  GET_SKILL_OPTIONS,
  GET_SKILL_OPTIONS_SUCCESS,
  GET_SKILL_OPTIONS_FAILED
} from "../actions/skillOptions";
import {errorMessageFromPayload} from "../utils/errors";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  list: [],
  one: null,
  error: '',
  loading: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_SKILL_OPTION: {
      return {
        ...state,
        loading: true,
        error: ''
      };
    }
    case GET_SKILL_OPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        one: action.payload
      };
    }
    case GET_SKILL_OPTION_FAILED: {
      return {
        ...state,
        loading: false,
        error: errorMessageFromPayload(action.payload)
      };
    }
    case GET_SKILL_OPTIONS: {
      return {
        ...state,
        loading: true,
        error: ''
      };
    }
    case GET_SKILL_OPTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: action.payload
      };
    }
    case GET_SKILL_OPTIONS_FAILED: {
      return {
        ...state,
        loading: false,
        error: errorMessageFromPayload(action.payload)
      };
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
