import {SKILL_ASSESSED, SKILL_ASSESSED_SUCCESS, SKILL_ASSESSED_FAILED} from '../actions/assessment'
import {errorMessageFromPayload} from "../utils/errors";
import {GET_MANEUVERS_FAILED, GET_MANEUVERS_SUCCEEDED} from "../actions/maneuvers";
import {GET_GLOBAL_SKILLS_FAILED, GET_GLOBAL_SKILLS_SUCCESS} from "../actions/globalSkills";
import {GET_SKILL_OPTIONS_FAILED, GET_SKILL_OPTIONS_SUCCESS} from "../actions/skillOptions";
import {START_LESSON_SUCCESS} from "../actions/startLesson";
import {INIT_STATE} from "../actions/init";
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";
import {LESSON_COMPLETE_SUCCESS} from "../actions/completeLesson";
import {GET_MANEUVER_TYPES_FAILED, GET_MANEUVER_TYPES_SUCCEEDED} from "../actions/maneuverTypes";


const defaultState = {
    error: '',
    lessonId: '',
    maneuvers: {},
    globalSkills: {},
    skillOptions: {},
    maneuverTypes: {}
};

function arrayToHash(arr, key) {
    return arr.reduce((a,c) => {
        a[c[key]] = c;
        return a;
    }, {});
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case INIT_STATE: {
            return {...state, ...action.payload.assessment};
        }
        case START_LESSON_SUCCESS: {
            const lessonId = action.payload;
            return {...state, lessonId, error: ''}
        }
        case GET_MANEUVERS_SUCCEEDED: {
            return {...state, maneuvers: arrayToHash(action.payload, 'maneuverId')};
        }
        case GET_MANEUVERS_FAILED: {
            return {...state, error: errorMessageFromPayload(action.payload)};
        }
        case GET_GLOBAL_SKILLS_SUCCESS: {
            return {...state, globalSkills: arrayToHash(action.payload, 'globalSkillId')};
        }
        case GET_GLOBAL_SKILLS_FAILED: {
            return {...state, error: errorMessageFromPayload(action.payload)};
        }
        case GET_SKILL_OPTIONS_SUCCESS: {
            return {...state, skillOptions: arrayToHash(action.payload, 'skillOptionId')};
        }
        case GET_SKILL_OPTIONS_FAILED: {
            return {...state, error: errorMessageFromPayload(action.payload)};
        }
        case GET_MANEUVER_TYPES_SUCCEEDED: {
            return {...state, maneuverTypes: arrayToHash(action.payload, 'maneuverTypeId')};
        }
        case GET_MANEUVER_TYPES_FAILED: {
            return {...state, error: errorMessageFromPayload(action.payload)};
        }
        case SKILL_ASSESSED: {
            return {...state, error: ''}
        }
        case SKILL_ASSESSED_SUCCESS: {
            return {...state}
        }
        case SKILL_ASSESSED_FAILED: {
            return {...state, error: errorMessageFromPayload(action.payload)}
        }
        case SYNC_RESET_DEVICE_SUCCESS: {
            return defaultState;
        }
        case LESSON_COMPLETE_SUCCESS: {
            return {...state, lessonId: ''}
        }
        default:
            return state;
    }
}
