import {localFind, localFindOne} from "../utils/api";

export const GET_MANEUVER = 'GET_MANEUVER';
export const GET_MANEUVER_SUCCEEDED = 'GET_MANEUVER_SUCCEEDED';
export const GET_MANEUVER_FAILED = 'GET_MANEUVER_FAILED';
export const getManeuver = localFindOne('v1/maneuver/findOne', GET_MANEUVER, GET_MANEUVER_SUCCEEDED, GET_MANEUVER_FAILED);

export const GET_MANEUVERS = 'GET_MANEUVERS';
export const GET_MANEUVERS_SUCCEEDED = 'GET_MANEUVERS_SUCCEEDED';
export const GET_MANEUVERS_FAILED = 'GET_MANEUVERS_FAILED';
export const getManeuvers = localFind('v1/maneuver/find', GET_MANEUVERS, GET_MANEUVERS_SUCCEEDED, GET_MANEUVERS_FAILED);
