import { GET_INSTRUCTOR, GET_INSTRUCTOR_SUCCESS } from '../actions/instructors'
import {SYNC_RESET_DEVICE_SUCCESS} from "../actions/sync";

const defaultState = {
  fetching: false,
  firstName: '',
  lastName: '',
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_INSTRUCTOR: {
      return {...state, fetching: true}
    }
    case GET_INSTRUCTOR_SUCCESS: {
      const {firstName, lastName} = action.payload;
      return {...state, fetching: false, firstName, lastName}
    }
    case SYNC_RESET_DEVICE_SUCCESS: {
      return defaultState;
    }
    default:
      return state;
  }
}
