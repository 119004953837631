export default class SkillAssessed {
    static type = 'SkillAssessed';
    /**
     * @param {!string} assessmentId
     * @param {!string} lessonId
     * @param {!string} maneuverId
     * @param {!string} globalSkillId
     * @param {!string} skillOptionId
     * @param {!string} maneuverTypeId
     * @param {!string} timestamp
     */
    constructor(assessmentId, lessonId, maneuverId, globalSkillId, skillOptionId, maneuverTypeId, timestamp) {
        if (typeof assessmentId !== 'string') throw new TypeError("assessmentId must be a string");
        if (typeof lessonId !== 'string') throw new TypeError("lessonId must be a string");
        if (typeof maneuverId !== 'string') throw new TypeError("maneuverId must be a string");
        if (typeof globalSkillId !== 'string') throw new TypeError("globalSkillId must be a string");
        if (typeof skillOptionId !== 'string') throw new TypeError("skillOptionId must be a string");
        if (typeof maneuverTypeId !== 'string') throw new TypeError("maneuverTypeId must be a string");
        if (typeof timestamp !== 'string') throw new TypeError("timestamp must be a string");
        this.assessmentId = assessmentId;
        this.lessonId = lessonId;
        this.maneuverId = maneuverId;
        this.globalSkillId = globalSkillId;
        this.skillOptionId = skillOptionId;
        this.maneuverTypeId = maneuverTypeId;
        this.timestamp = timestamp;
    }
}
