import React, {Component} from "react"
import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {bindActionCreators} from 'redux'
import {addGlobalSkill} from "../actions/addGlobalSkill";
import {Form} from "react-bootstrap";

class AddGlobalSkillPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    // state
    adding: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    // actions
    addGlobalSkill: PropTypes.func.isRequired,
  };

  state = {
    name: '',
    isIndependent: false,
    isError: true,
  };

  setFormValue = (evt) => this.setState({[evt.target.name]: evt.target.value});

  setFormCheckbox = (evt) => this.setState({[evt.target.name]: evt.target.checked});

  onSubmit = async (evt) => {
    evt.preventDefault();

    const {addGlobalSkill, history} = this.props;
    const {...data} = this.state;
    try {
      await addGlobalSkill(data);
      history.goBack();
    } catch (err) {
      //nothing
    }
  };

  render() {
    return (
      <div id="AddGlobalSkillPage">
        <h3>Add Global Skill</h3>
        <div className="error">{this.props.error}</div>
        <div>
          <Form onSubmit={this.onSubmit}>
              <Form.Group>
                <Form.Label>Name:</Form.Label>
                <Form.Control type="text" name="name" value={this.state.name} onChange={this.setFormValue}/>
              </Form.Group>
              <Form.Group>
                <Form.Check label="Independent" type="checkbox" name="isIndependent" checked={this.state.isIndependent} onChange={this.setFormCheckbox} />
              </Form.Group>
              <Form.Group>
                <Form.Check label="Error" type="checkbox" name="isError" checked={this.state.isError} onChange={this.setFormCheckbox} />
              </Form.Group>
              <Form.Group>
                <input disabled={this.props.adding} type="submit" value="Add"/>
              </Form.Group>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.addGlobalSkill
});
const mapDispatchToProps = dispatch => bindActionCreators({
  addGlobalSkill
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddGlobalSkillPage);
