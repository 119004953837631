export default class LessonCompleted {
    static type = 'LessonCompleted';
    /**
     * @param {!string} lessonId
     * @param {!string} timestamp
     * @param {!string} signature
     */
    constructor(lessonId, timestamp, signature) {
        if (typeof lessonId !== 'string') throw new TypeError("lessonId must be a string");
        if (typeof timestamp !== 'string') throw new TypeError("timestamp must be a string");
        if (typeof signature !== 'string') throw new TypeError("signature must be a string");
        this.lessonId = lessonId;
        this.timestamp = timestamp;
        this.signature = signature;
    }
}