export const INIT_AUTH = 'INIT_AUTH';
export const INIT_STATE = 'INIT_STATE';

/**
 * @param {object} payload
 * @param {string} payload.username
 * @param {string} payload.token
 * @returns {{payload: *, type: string}}
 */
export function initAuth(payload) {
  return {type: INIT_AUTH, payload}
}
