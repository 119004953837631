export default class LessonStarted {
    static type = 'LessonStarted';
    /**
     * @param {!string} lessonId
     * @param {!string} instructorId
     * @param {!string} studentId
     * @param {!string} lessonDateTime
     */
    constructor(lessonId, instructorId, studentId, lessonDateTime) {
        if (typeof lessonId !== 'string') throw new TypeError("lessonId must be a string");
        if (typeof instructorId !== 'string') throw new TypeError("instructorId must be a string");
        if (typeof studentId !== 'string') throw new TypeError("studentId must be a string");
        if (typeof lessonDateTime !== 'string') throw new TypeError("lessonDateTime must be a string");
        this.lessonId = lessonId;
        this.instructorId = instructorId;
        this.studentId = studentId;
        this.lessonDateTime = lessonDateTime;
    }
}