import React, {Component} from "react"
import PropTypes from 'prop-types'
import {connect} from "react-redux"
import { bindActionCreators } from 'redux'
import {registerStudent} from '../actions/students'

const defaultState = {
  added: false,
  name: '',
  licenseNo: '',
  phoneNo: '',
  email: '',
  testDate: '',
  testTime: '',
  testLocation: '',
  emergencyContactNo: '',
  emergencyContactName: '',
  allergies: '',
  isAdmin: false
}

export class RegisterStudent extends Component {
  static propTypes = {
    // state
    adding: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    // actions
    registerStudent: PropTypes.func.isRequired,
  }

  state = Object.assign({}, defaultState)

  setFormValue = (evt) => this.setState({[evt.target.name]: evt.target.value});

  onSubmit = async(evt) => {
    evt.preventDefault();

    const {registerStudent} = this.props;
    const {added, isAdmin, ...data} = this.state;
    try {
      await registerStudent({...data, roles: isAdmin ? ['admin'] : []});
      this.setState({...defaultState, added: true})
    } catch (err) {
      //nothing
    }

    return false;
  }

  render() {
    return <div id="RegisterStudentPage">
      <h3>Register student</h3>
      {this.state.added && <div className="success">Student registered</div>}
      <div className="error">{this.props.error}</div>
      <div>
        <form onSubmit={this.onSubmit}>
          <table>
            <tbody>
            <tr>
              <td>Name:</td>
              <td><input type="text" name="name" value={this.state.name} onChange={this.setFormValue} /></td>
            </tr>
            <tr>
              <td>License No.:</td>
              <td><input type="text" name="licenseNo" value={this.state.licenseNo} onChange={this.setFormValue} /></td>
            </tr>
            <tr>
              <td>Phone No.:</td>
              <td><input type="text" name="phoneNo" value={this.state.phoneNo} onChange={this.setFormValue} /></td>
            </tr>
            <tr>
              <td>Email:</td>
              <td><input type="text" name="email" value={this.state.email} onChange={this.setFormValue} /></td>
            </tr>
            <tr>
              <td>Test Date:</td>
              <td><input type="text" name="testDate" value={this.state.testDate} onChange={this.setFormValue} /></td>
            </tr>
            <tr>
              <td>Test Time:</td>
              <td><input type="text" name="testTime" value={this.state.testTime} onChange={this.setFormValue} /></td>
            </tr>
            <tr>
              <td>Test Location:</td>
              <td><input type="text" name="testLocation" value={this.state.testLocation} onChange={this.setFormValue} /></td>
            </tr>
            <tr>
              <td>Emergency Contact No.:</td>
              <td><input type="text" name="emergencyContactNo" value={this.state.emergencyContactNo} onChange={this.setFormValue} /></td>
            </tr>
            <tr>
              <td>Emergency Contact Name:</td>
              <td><input type="text" name="emergencyContactName" value={this.state.emergencyContactName} onChange={this.setFormValue} /></td>
            </tr>
            <tr>
              <td>Allergies:</td>
              <td><input type="text" name="allergies" value={this.state.allergies} onChange={this.setFormValue} /></td>
            </tr>
            <tr>
              <td colSpan="2">
                <input disabled={this.props.adding} type="submit" value="Add" />
              </td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  ...state.registerStudent
})
const mapDispatchToProps = dispatch => bindActionCreators({
  registerStudent
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStudent);
